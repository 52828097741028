import instanceCommon from "../instances/common"
import { getToken } from "../helpers/localstorage"

export const authInterceptor = () => {
  instanceCommon.interceptors.request.use((config) => {
    const token = getToken()
    if (token) {
      config.headers.Authorization = `${token}`
    }

    return config
  })
}
