import React from "react"

import { Button, Hidden } from "@material-ui/core"
import CircularProgress from "@material-ui/core/CircularProgress"

import { useStyles } from "./styles"

const CancelConfirmButtons = (props) => {
  const {
    onClickCancel,
    onClickSubmit,
    cancelText = "Cancelar",
    submitText = "Confirmar",
    disabledCancel,
    disabledSubmit,
    styleSubmit,
    isLoading,
    leftButton = false,
    leftButtonOnClick,
    leftButtonDisabled,
    leftButtonText,
    noMargin = false,
  } = props
  const classes = useStyles({ noMargin })
  return (
    <div className={classes.allButtons}>
      <div>
        <Hidden mdDown>
          {leftButton ? (
            <Button
              className={classes.textType}
              onClick={leftButtonOnClick}
              disabled={leftButtonDisabled}
            >
              {leftButtonText}
            </Button>
          ) : null}
        </Hidden>
      </div>
      <div className={classes.buttons}>
        <Button
          className={classes.cancel}
          onClick={onClickCancel}
          disabled={disabledCancel}
        >
          {cancelText}
        </Button>
        <Button
          className={classes.textType}
          style={styleSubmit}
          variant="contained"
          color="primary"
          type="submit"
          onClick={onClickSubmit}
          disabled={disabledSubmit}
          {...props}
        >
          {isLoading ? (
            <CircularProgress
              style={{
                color: "#fff",
                width: "24px",
                height: "24px",
                marginLeft: "21px",
                marginRight: "21px",
              }}
            />
          ) : (
            submitText
          )}
        </Button>
      </div>
    </div>
  )
}
export default CancelConfirmButtons
