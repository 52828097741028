import React from "react"

import { MenuItem, Typography, ListItemIcon } from "@material-ui/core"

export const CustomMenuItems = (props) => {
  const { menuItems, onClose } = props
  return (
    <div>
      {menuItems.map((item, index) => {
        function onClick() {
          onClose()
          item.onClick()
        }
        return item.icon ? (
          <MenuItem
            key={index}
            style={{ width: "100%" }}
            component="button"
            onClick={() => onClick()}
          >
            <ListItemIcon>{item.icon}</ListItemIcon>
            <Typography variant="inherit">{item.title}</Typography>
          </MenuItem>
        ) : (
          <MenuItem
            key={index}
            component="button"
            style={{ width: "100%" }}
            onClick={() => onClick()}
          >
            <Typography
              variant="inherit"
              style={{ textAlign: "center", width: "100%" }}
            >
              {item.title}
            </Typography>
          </MenuItem>
        )
      })}
    </div>
  )
}
