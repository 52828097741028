import React, { useState, useRef } from "react"

import { Button, Tooltip, MenuItem } from "@material-ui/core"
import ExpandLess from "@material-ui/icons/ExpandLess"
import ExpandMore from "@material-ui/icons/ExpandMore"
import ClickAwayListener from "@material-ui/core/ClickAwayListener"
import GetAppIcon from "@material-ui/icons/GetApp"
import { useHistory } from "react-router-dom"

import NavBarGlobal from "Components/UI/Molecules/NavBar"
import TemplateGlobal from "Components/UI/Templates/Common"
import Export from "Components/UI/Organisms/Insurance/Export"

import Grow from "@material-ui/core/Grow"
import Popper from "@material-ui/core/Popper"
import MenuList from "@material-ui/core/MenuList"
import Paper from "@material-ui/core/Paper"
import Profile from "Components/UI/Organisms/Insurance/Profile"

import TableInsurance from "Components/UI/Organisms/Insurance/Table"
import { axiosService } from "service"

const TemplateInsurance = () => {
  let history = useHistory()
  const anchorRef = useRef(null)

  const [render, setRender] = useState(true)
  const [openPerfil, setOpenPerfil] = useState(false)
  const [open, setOpen] = useState(false)
  const [openExport, setOpenExport] = useState(false)

  const handleOpenExport = () => {
    setOpenExport(true)
  }

  const handleCloseExport = () => {
    setOpenExport(false)
  }

  const handleOpenPerfil = () => {
    setOpenPerfil(true)
  }

  const handleCloseProfile = () => {
    setOpenPerfil(false)
    setOpen(false)
  }

  const backToLogin = () => {
    axiosService.logout()
    history.push("/")
  }

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return
    }
    setOpen(false)
  }
  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen)
  }

  const BUTTONS_MOBILE = [
    {
      id: 1,
      title: "Perfil",
      onClick: handleOpenPerfil,
    },
    {
      id: 2,
      title: "Cerrar Sesion",
      onClick: backToLogin,
    },
  ]

  const renderMenu = (
    <Popper
      open={open}
      anchorEl={anchorRef.current}
      role={undefined}
      transition
      style={{ zIndex: 9999 }}
    >
      {({ TransitionProps, placement }) => (
        <Grow
          {...TransitionProps}
          style={{
            transformOrigin:
              placement === "bottom" ? "center top" : "center bottom",
            marginRight: "6px",
          }}
        >
          <Paper>
            <ClickAwayListener onClickAway={handleClose}>
              <MenuList
                anchororigin={{ vertical: "top", horizontal: "right" }}
                transformOrigin={{ vertical: "top", horizontal: "center" }}
              >
                <MenuItem onClick={() => handleOpenPerfil()}>Perfil</MenuItem>
                <MenuItem onClick={() => backToLogin()}>Cerrar sesión</MenuItem>
              </MenuList>
            </ClickAwayListener>
          </Paper>
        </Grow>
      )}
    </Popper>
  )

  return (
    <>
      <TemplateGlobal
        navbar={
          <NavBarGlobal
            buttonsMobile={BUTTONS_MOBILE}
            title={`Bienvenido/a, ${JSON.parse(
              localStorage.getItem("user")
            )}   ${JSON.parse(localStorage.getItem("lastname"))}`}
            subTitle={`  ${JSON.parse(localStorage.getItem("cia_seguro"))} `}
            buttons={[
              <Tooltip title="Exportar">
                <Button variant="contained" onClick={handleOpenExport}>
                  <GetAppIcon style={{ color: "black" }} />
                </Button>
              </Tooltip>,
              <Button
                ref={anchorRef}
                onClick={handleToggle}
                endIcon={open ? <ExpandLess /> : <ExpandMore />}
                variant="contained"
                color="primary"
                style={{ backgroundColor: "#00867E", color: "white" }}
              >
                {" "}
                <span style={{ whiteSpace: "nowrap" }}>
                  {` ${JSON.parse(localStorage.getItem("user"))}`}
                </span>
              </Button>,
            ]}
          />
        }
        table={<TableInsurance render={render} setRender={setRender} />}
      />
      <Profile openPerfil={openPerfil} handleClose={handleCloseProfile} />
      <Export open={openExport} handleClose={handleCloseExport} />
      {renderMenu}
    </>
  )
}

export default TemplateInsurance
