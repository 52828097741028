import React, { useRef } from "react"

import { Button } from "@material-ui/core"

import DialogComponent from "Components/UI/Molecules/Modals/DialogComponent"
import useStyles from "./styles"
import FormPassword from "Components/UI/Atoms/NewPassword/Form"
import useCustomSnackbar from "Components/UI/Atoms/SnackBar"
import { interfaces } from "service/interfaces"

const NewPassword = (props) => {
  const { open, handleClose, disabled, subtitle, setOpenNewPassword } = props

  const createDismissableSnackbar = useCustomSnackbar()
  const classes = useStyles()
  const formSubmitRef = useRef(null)

  const handleSubmit = () => {
    formSubmitRef.current.click()
  }

  const onSubmit = async (values) => {
    await interfaces.insuranceInterface
      .insuranceResetPassword(values)
      .then((res) =>
        createDismissableSnackbar(res.data, {
          variant: "success",
        })
      )
      .catch((error) =>
        createDismissableSnackbar(error.response.data.data, {
          variant: "error",
        })
      )
      .finally(setOpenNewPassword(false))
  }

  return (
    <DialogComponent
      open={open}
      handleclose={handleClose}
      title="Cambio de contraseña"
      disableBackdropClick
      disableEscapeKeyDown
      children={
        <FormPassword
          subtitle={subtitle}
          ref={formSubmitRef}
          onSubmit={onSubmit}
        />
      }
      actions={
        <div className={classes.div}>
          <Button
            className={classes.textTransformButtons}
            onClick={handleClose}
            disabled={!disabled ? false : true}
          >
            Cancelar
          </Button>
          <Button
            onClick={handleSubmit}
            className={classes.textTransformButtons}
            color="primary"
            variant="contained"
            type="submit"
          >
            Confirmar
          </Button>
        </div>
      }
    />
  )
}

export default NewPassword
