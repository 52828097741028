const setItem = (item, value) => {
  sessionStorage.setItem(item, value)
}

const getItem = (item) => {
  sessionStorage.getItem(item)
}

const removeItem = (item) => {
  sessionStorage.removeItem(item)
}

export const handleSessionStorage = {
  getItem,
  setItem,
  removeItem,
}
