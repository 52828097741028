import { makeStyles } from "@material-ui/core"

const useStyles = makeStyles((theme) => ({
  textTransformButtons: {
    textTransform: "capitalize",
  },
  div: {
    width: "100%",
    display: "flex",
    justifyContent: "flex-end",
    marginRight: "15px",
  },
}))
export default useStyles
