import React, { useContext } from "react"
import { Link, useLocation } from "react-router-dom"

import { List, ListItemText, Collapse } from "@material-ui/core"
import ExpandLess from "@material-ui/icons/ExpandLess"
import ExpandMore from "@material-ui/icons/ExpandMore"
import HomeIcon from "@material-ui/icons/Home"

import MuiListItem from "@material-ui/core/ListItem"
import { withStyles } from "@material-ui/core/styles"
import ListItemIcon from "@material-ui/core/ListItemIcon"
import { OpenNavbar } from "Components/Hooks/ContextSideBar"
import useStyles from "./styles"

const ListItem = withStyles({
  root: {
    borderRadius: "15px",
    color: "#414141",
    "&$selected": {
      backgroundColor: "#019966",
      color: "#FFFFFF",
    },
    "&$selected:hover": {
      backgroundColor: "#019966",
      color: "#FFFFFF",
    },
    "&:hover": {
      backgroundColor: "#3D996050",
      color: "white",
    },
  },
  selected: {},
})(MuiListItem)

const SideBar = () => {
  const classes = useStyles()
  const { setOpenSideBar } = useContext(OpenNavbar)

  const { pathname } = useLocation()

  function handleClose() {
    setOpenSideBar(false)
  }
  const itemList = [
    {
      text: "Aseguradoras",
      icon: <HomeIcon />,
      keys: "home",
      to: "/aseguradoras",
      collapse: false,
    },
  ]

  const ListData = () =>
    itemList.map((item) => {
      const {
        text,
        icon,
        to,
        keys,
        collapse,
        submenu,
        click,
        open,
        menu,
        style,
      } = item

      return (
        <>
          {collapse ? (
            <>
              <ListItem button onClick={click} style={style}>
                <ListItemIcon>{icon}</ListItemIcon>
                <ListItemText primary={text} />
                {open ? <ExpandLess /> : <ExpandMore />}
              </ListItem>
              <Collapse in={open} timeout="auto">
                {submenu.map((subitem) => (
                  <List key={subitem.id} component="div" disablePadding>
                    <ListItem
                      button
                      key={subitem.keys}
                      className={classes.nested}
                      selected={
                        pathname === subitem.to ? (menu(true), true) : false
                      }
                      component={Link}
                      to={subitem.to}
                      onClick={subitem.onClick}
                    >
                      <ListItemText primary={subitem.text} />
                    </ListItem>
                  </List>
                ))}
              </Collapse>
            </>
          ) : (
            <ListItem
              onClick={handleClose}
              className={classes.listItem}
              button
              key={keys}
              to={to}
              component={Link}
              selected={pathname === to}
              style={style}
            >
              {icon ? <ListItemIcon>{icon} </ListItemIcon> : null}

              <ListItemText>{text}</ListItemText>
            </ListItem>
          )}
        </>
      )
    })

  return (
    <div className={classes.root} style={{ overflowY: "scroll" }}>
      <List className={classes.lista}>
        <div className={classes.divLogo}>
          <img
            src={process.env.PUBLIC_URL + "/Images/personal.png"}
            alt="Logo"
            className={classes.logo}
          />
        </div>
        <ListData />
      </List>
    </div>
  )
}

export default SideBar
