import React from "react"

import AppBar from "@material-ui/core/AppBar"
import Toolbar from "@material-ui/core/Toolbar"
import Typography from "@material-ui/core/Typography"
import Hidden from "@material-ui/core/Hidden"

import { useStyles } from "./styles"

const Title = (props) => {
  const { actions, subtitle, subtitle2, title } = props

  const classes = useStyles()

  return (
    <AppBar position="relative" className={classes.root}>
      <Toolbar>
        <Typography variant="h4" className={classes.title}>
          {title}
        </Typography>

        <div style={{ flexDirection: "column", display: "flex" }}>
          <Typography variant="body2">
            <strong> Siniestro: </strong>
            {subtitle}
          </Typography>
          <Hidden smDown>
            <Typography variant="body2">
              <strong> Perteneciente a:</strong> {subtitle2}
            </Typography>
          </Hidden>
        </div>
        {actions}
      </Toolbar>
    </AppBar>
  )
}

export default Title
