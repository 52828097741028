import React from "react"
import { BrowserRouter, Switch } from "react-router-dom"

import Insurance from "./Pages/Insurance"
import Insured from "./Pages/Insured"
import HomeInsurance from "./Pages/HomeInsurance"
import Layout from "./Layout.js"

import PrivateRoute from "Components/Routing/Private"
import PublicRoute from "Components/Routing/Public"

import FormDataContent from "Components/Hooks/ContextForm"

const App = () => {
  return (
    <FormDataContent>
      <BrowserRouter>
        <Switch>
          <PublicRoute exact path="/" component={Insurance} />
          <PublicRoute exact path="/asegurado" component={Insured} />
          <Layout>
            <PrivateRoute
              exact
              path="/aseguradoras"
              component={HomeInsurance}
            />
          </Layout>
        </Switch>
      </BrowserRouter>
    </FormDataContent>
  )
}

export default App
