import React, { useContext } from "react"
import { Formik, Form } from "formik"
import TimeLine from "Components/UI/Organisms/Insured/Content/TimeLine"
import InputsWithoutValidate from "Components/UI/Molecules/InputsWithoutValidate"

import {
  FORM_HOME,
  FORM_RETIREMENT,
} from "Components/UI/Organisms/Insured/Content/options"
import { FormDataContent } from "Components/Hooks/ContextForm"

export default function FormGlobal(props) {
  const { initialState, validationSchema, handleSubmit, ref, indexStep } = props
  const { initialData } = useContext(FormDataContent)

  function _renderForms(number) {
    switch (number) {
      case 0:
        return <TimeLine />
      case 1:
        return (
          <div style={{ marginTop: "10px", padding: "3em" }}>
            <InputsWithoutValidate
              fields={FORM_HOME(initialData)}
              spacing={3}
            />
          </div>
        )
      case 2:
        return (
          <div style={{ marginTop: "10px", padding: "3em" }}>
            <InputsWithoutValidate
              fields={FORM_RETIREMENT(initialData)}
              spacing={3}
            />
          </div>
        )
      default:
        break
    }
  }
  return (
    <>
      <Formik
        validateOnChange={true}
        enableReinitialize
        initialValues={initialState}
        validationSchema={validationSchema}
        onSubmit={(values, errors) => {
          handleSubmit(values, errors)
        }}
      >
        {({ isSubmitting, errors }) => (
          <Form>
            {_renderForms(indexStep)}
            <button
              ref={ref}
              style={{ display: "none" }}
              type="submit"
              onClick={(values) => {
                handleSubmit(values, errors)
              }}
              disabled={isSubmitting}
            />
          </Form>
        )}
      </Formik>
    </>
  )
}
