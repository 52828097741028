export const fields = (insurance, editMode) => {
  return [
    {
      class: "textfield",
      value: insurance.compañia,
      disabled: true,
      label: "Compañía",
      name: "compañía",
      sm: 12,
      xs: 12,
    },
    {
      label: "Nombre de usuario",
      value: insurance.username,
      disabled: true,
      name: "username",
      sm: 12,
      xs: 12,
      class: "textfield",
    },
    {
      class: "textfield",
      value: insurance.nombre,
      disabled: editMode,
      label: "Nombre",
      name: "nombre",
      sm: 12,
      xs: 12,
    },
    {
      value: insurance.apellido,
      disabled: editMode,
      label: "Apellido",
      name: "apellido",
      sm: 12,
      xs: 12,
      class: "textfield",
    },
    {
      label: "Correo electrónico",
      value: insurance.email,
      disabled: editMode,
      name: "email",
      sm: 12,
      xs: 12,
      class: "textfield",
    },
    {
      label: "Teléfono",
      name: "telefono",
      disabled: editMode,
      value: insurance.telefono,
      sm: 12,
      xs: 12,
      class: "textfield",
      type: "Number",
    },
  ]
}
