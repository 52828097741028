import React, { useEffect, useState } from "react"
import MUIDataTable from "mui-datatables"

import { MuiThemeProvider } from "@material-ui/core/styles"

import { getMuiTheme } from "./styles"
import { Options } from "Components/UI/Molecules/Table/Options"

const MuiDatables = (props) => {
  const {
    title,
    data,
    columns,
    apiCall,
    count,
    textPlaceholder,
    render,
    setRender,
    columnTheme,
    searchEnabled,
    expandableRows,
    renderExpandableRow,
    selectableRows,
    customToolbarSelect,
    customToolbar,
    padding,
    filterEnabled,
    isRowSelectable,
    setRowProps,
    rows = 25,
    advancedSearch,
    onRowClick,
    searchFirst,
  } = props

  const [offset, setOffset] = useState(0)
  const [isSearching, setIsSearching] = useState(true)
  const [sort, setSort] = useState({
    orderBy: "id",
    order: "desc",
  })
  const [filters, setFilters] = useState()
  const [search, setSearch] = useState()

  const functions = {
    render,
    filters,
    setRender,
    setSort,
    setSearch,
    setOffset,
    setFilters,
    isSearching,
    count,
    textPlaceholder,
    searchEnabled,
    expandableRows,
    renderExpandableRow,
    selectableRows,
    customToolbarSelect,
    customToolbar,
    padding,
    filterEnabled,
    isRowSelectable,
    setRowProps,
    rows,
    onRowClick,
    searchFirst,
  }

  useEffect(() => {
    setIsSearching(true)
    let options = {
      offset: `?offset=${offset}`,
      limit: `&limit=${rows}`,
      orderby: `&orderby=${sort.orderBy}`,
      order: `&order=${sort.order}`,
      search: "",
      filters: filters,
    }
    if (search !== undefined) {
      options.search = `&search=${search}`
    }
    if (advancedSearch && advancedSearch !== "") {
      options.search = advancedSearch
    }
    let finalQuery = Object.values(options).join("")
    apiCall(finalQuery, function () {
      setIsSearching(false)
    })
    // eslint-disable-next-line
  }, [render])

  return (
    <React.Fragment>
      <MuiThemeProvider theme={getMuiTheme(columnTheme)}>
        <MUIDataTable
          title={title}
          data={data}
          columns={columns}
          options={Options(functions)}
        />
      </MuiThemeProvider>
    </React.Fragment>
  )
}
export default MuiDatables
