import React from "react"
import { Route, Redirect } from "react-router-dom"

import { isAuthenticated } from "Helpers"

const PrivateRoute = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) =>
        isAuthenticated() ? <Component {...props} /> : <Redirect to="/" />
      }
    />
  )
}

export default PrivateRoute
