import React, { createContext, useState } from "react"

// eslint-disable-next-line
export default ({ children }) => {
  const [openSideBar, setOpenSideBar] = useState(false)

  return (
    <OpenNavbar.Provider value={{ openSideBar, setOpenSideBar }}>
      {children}
    </OpenNavbar.Provider>
  )
}

export const OpenNavbar = createContext()
