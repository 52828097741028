import React, { useState, useContext } from "react"
import LoginTemplate from "Components/UI/Templates/Login"
import { interfaces } from "service/interfaces"
import { validationInsured } from "Components/UI/Validation"

import { FormDataContent } from "Components/Hooks/ContextForm"

const ContainerInsured = () => {
  const [openDialog, setOpenDialog] = useState(false)

  const { setInitialData } = useContext(FormDataContent)

  async function InsuredInfo(values, resetForm, setErrors, setFieldValue) {
    await interfaces.insuranceInterface
      .insuredInfo(values)
      .then((res) => {
        setInitialData(res.data)
        setOpenDialog(true)
      })
      .catch((error) => {
        setErrors({
          dominio:
            error.response.data.data ===
            "No existe un vehículo con los datos ingresados"
              ? resetForm({})
              : null,
          nro_siniestro:
            error.response.data.data ===
            "Nro. de siniestro no corresponde al dominio ingresado."
              ? error.response.data.data
              : " ",
        })
        setFieldValue("password", "", null)
      })
  }

  return (
    <LoginTemplate
      text="Inicio asegurados"
      login={InsuredInfo}
      validation={validationInsured}
      openDialog={openDialog}
      setOpenDialog={setOpenDialog}
      firstLabel="Dominio"
      secondLabel="Número de siniestro"
      namefirstLabel="dominio"
      namesecondLabel="nro_siniestro"
      buttonLabel="Consultar"
    />
  )
}

export default ContainerInsured
