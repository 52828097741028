import React from "react"
import { Button } from "@material-ui/core"
import CircularProgress from "@material-ui/core/CircularProgress"

import { useStyles } from "./styles"

const CustomButton = (props) => {
  const classes = useStyles()
  const {
    disabled,
    onClick,
    color,
    text,
    fullWidth,
    style,
    ref,
    startIcon,
    onSubmit,
    type,
    variant = "contained",
    href,
    target,
    endIcon,
    isLoading,
  } = props

  return (
    <Button
      href={href}
      target={target}
      ref={ref}
      type={type}
      onSubmit={onSubmit}
      disabled={disabled}
      startIcon={startIcon}
      style={style}
      onClick={onClick}
      fullWidth={fullWidth}
      color={color}
      variant={variant}
      endIcon={endIcon}
      className={classes.textType}
    >
      {isLoading ? (
        <CircularProgress
          style={{
            color: "#fff",
            width: "24px",
            height: "24px",
            marginLeft: "21px",
            marginRight: "21px",
          }}
        />
      ) : (
        text
      )}
    </Button>
  )
}

export default CustomButton
