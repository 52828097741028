import { axiosService } from "service"
import { insuranceEndpoints } from "service/sources/insurance"

const listOfSinister = (options) => {
  return axiosService.get(insuranceEndpoints.LIST_OF_SINISTER + options)
}
const allInfoOfSinister = (id) => {
  return axiosService.get(insuranceEndpoints.ALL_INFO_SINISTER + "/" + id)
}
const insuranceProfile = () => {
  return axiosService.get(insuranceEndpoints.INSURANCE_PROFILE)
}

const updateInsuranceProfile = (body) => {
  return axiosService.put(insuranceEndpoints.UPDATE_INSURANCE_PROFILE, body)
}
const insuranceLogIn = (user, pass) => {
  return axiosService.authLogin(insuranceEndpoints.INSURANCE_LOGIN, user, pass)
}
const insuranceResetPassword = (pass) => {
  return axiosService.post(insuranceEndpoints.INSURANCE_RESET_PASSWORD, pass)
}

const resetPasswordLogin = (pass) => {
  return axiosService.post(insuranceEndpoints.RESET_PASSWORD_LOGIN, pass)
}

const resetPasswordHome = (pass) => {
  return axiosService.post(insuranceEndpoints.RESET_PASSWORD_HOME, pass)
}

const insuredInfo = (body) => {
  return axiosService.post(insuranceEndpoints.INSURED_INFO, body)
}

const qualification = (id, body) => {
  return axiosService.post(insuranceEndpoints.QUALIFICATION + "/" + id, body)
}

const exportData = () => {
  return axiosService.get(insuranceEndpoints.EXPORT_DATA)
}

const print = (id) => {
  return axiosService.getPDF(insuranceEndpoints.PRINT + "/" + id)
}

export const insuranceInterface = {
  listOfSinister,
  allInfoOfSinister,
  insuranceProfile,
  updateInsuranceProfile,
  insuranceLogIn,
  insuranceResetPassword,
  resetPasswordLogin,
  resetPasswordHome,
  insuredInfo,
  qualification,
  exportData,
  print,
}
