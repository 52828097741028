import React from "react"
import { Field } from "formik"
import { TextField } from "formik-material-ui"

import MuiAutocomplete from "@material-ui/lab/Autocomplete"
import MuiTextField from "@material-ui/core/TextField"
import { createFilterOptions } from "@material-ui/lab/Autocomplete"
import Chip from "@material-ui/core/Chip"

import useStyles from "./styles"

const CustomAutocomplete = (props) => {
  const {
    getOptionLabel,
    disabled = false,
    options,
    defaultValue = null,
    onChange,
    label,
    name,
    disableClearable = false,
    value,
    getOptionDisabled,
    freeSolo = false,
    onInputChange,
    onKeyDown,
    heightInput,
  } = props
  const filterOptions = createFilterOptions({
    matchFrom: "start",
  })

  return (
    <div>
      <MuiAutocomplete
        onKeyDown={onKeyDown}
        getOptionDisabled={getOptionDisabled}
        freeSolo={freeSolo}
        onInputChange={onInputChange}
        disableClearable={disableClearable}
        filterOptions={filterOptions}
        getOptionLabel={getOptionLabel}
        disabled={disabled}
        noOptionsText={"No se encontraron resultados"}
        openText={"Abrir"}
        clearText={"Borrar"}
        options={options}
        defaultValue={defaultValue}
        onChange={onChange}
        value={value}
        renderInput={(params) => (
          <MuiTextField
            {...params}
            style={{ height: heightInput }}
            name={name}
            fullWidth
            label={label}
            variant="outlined"
            InputLabelProps={{
              shrink: true,
            }}
          />
        )}
      />
    </div>
  )
}

const FormikAutocomplete = (props) => {
  const {
    getOptionLabel,
    disabled = false,
    options,
    defaultValue = null,
    onChange,
    label,
    name,
    disableClearable = false,
    value,
    getOptionDisabled,
    freeSolo = false,
    autoSelect = false,
    style,
    sortBy,
    onInputChange,
  } = props
  const filterOptions = createFilterOptions({
    matchFrom: "start",
  })

  const classes = useStyles()

  return (
    <div>
      <MuiAutocomplete
        freeSolo={freeSolo}
        autoSelect={autoSelect}
        getOptionDisabled={getOptionDisabled}
        disableClearable={disableClearable}
        filterOptions={filterOptions}
        getOptionLabel={getOptionLabel}
        disabled={disabled}
        noOptionsText={"No se encontraron resultados"}
        openText={"Abrir"}
        closeText={"Cerrar"}
        clearText={"Borrar"}
        options={options.sort(function (a, b) {
          if (a[sortBy] > b[sortBy]) {
            return 1
          }
          if (a[sortBy] < b[sortBy]) {
            return -1
          }
          return 0
        })}
        defaultValue={defaultValue}
        value={value}
        onInputChange={onInputChange}
        onChange={onChange}
        renderInput={(params) => (
          <Field
            {...params}
            style={style}
            component={TextField}
            className={classes.autocomplete}
            name={name}
            fullWidth
            label={label}
            variant="outlined"
            InputLabelProps={{
              shrink: true,
            }}
          />
        )}
      />
    </div>
  )
}

const MultiAutocomple = (props) => {
  const {
    getOptionLabel,
    disabled = false,
    options,
    onChange,
    label,
    name,
    disableClearable = false,
    value,
    getOptionDisabled,
    autoSelect = false,
    heightInput,
    sortBy,
    tagName,
  } = props

  const filterOptions = createFilterOptions({
    matchFrom: "start",
  })

  const classes = useStyles()

  return (
    <MuiAutocomplete
      multiple
      renderTags={(tagValue, getTagProps) =>
        tagValue.map((option, index) => (
          <Chip label={option[tagName]} {...getTagProps({ index })} />
        ))
      }
      onChange={onChange}
      disableClearable={disableClearable}
      getOptionDisabled={getOptionDisabled}
      autoSelect={autoSelect}
      options={options.sort(function (a, b) {
        if (a[sortBy] > b[sortBy]) {
          return 1
        }
        if (a[sortBy] < b[sortBy]) {
          return -1
        }
        return 0
      })}
      value={value}
      filterOptions={filterOptions}
      getOptionLabel={getOptionLabel}
      disabled={disabled}
      noOptionsText={"No se encontraron resultados"}
      openText={"Abrir"}
      closeText={"Cerrar"}
      clearText={"Borrar"}
      renderInput={(params) => (
        <MuiTextField
          {...params}
          style={{ height: heightInput }}
          className={classes.autocomplete}
          name={name}
          fullWidth
          label={label}
          variant="outlined"
          InputLabelProps={{
            shrink: true,
          }}
        />
      )}
    />
  )
}

export { CustomAutocomplete, FormikAutocomplete, MultiAutocomple }
