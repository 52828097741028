import { useSnackbar } from "notistack"
import { useCallback } from "react"

const useCustomSnackbar = () => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()
  const createDismissableSnackbar = useCallback(
    (text = "---", opts) => {
      const key = enqueueSnackbar(text, {
        ...opts,
        onClick: () => {
          closeSnackbar(key)
        },
      })
    },
    [enqueueSnackbar, closeSnackbar]
  )

  return createDismissableSnackbar
}
 
export default useCustomSnackbar
