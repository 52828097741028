import { createBrowserHistory } from "history"
import moment from "moment"

import { Box } from "@material-ui/core"

import { getExpireTokenDate, getUserId } from "service/helpers/localstorage"
import { axiosService } from "service"

const TabPanel = (props) => {
  const { children, value, index } = props
  return (
    <div>
      {value === index && (
        <Box>
          <div>{children}</div>
        </Box>
      )}
    </div>
  )
}

const History = createBrowserHistory()

function checkTokenExpireInsurance() {
  const timeLeft = moment(getExpireTokenDate()).diff(
    moment(Date.now()),
    "seconds"
  )
  if (timeLeft <= 0) {
    localStorage.clear()
    History.push("/aseguradoras")
    window.location.reload()
  } else if (timeLeft < 600) {
    axiosService.refreshTokenInsurance(getUserId())
  }
}

const isAuthenticated = () => {
  const timeLeft = moment(getExpireTokenDate()).diff(
    moment(Date.now()),
    "seconds"
  )
  try {
    if (timeLeft > 0) {
      return true
    } else {
      return false
    }
  } catch (error) {
    return false
  }
}

function objectValuesToUpperCase(data, ignored) {
  let upperCased = {}

  for (var key in data) {
    if (typeof data[key] === "string" && key !== ignored) {
      upperCased[key] = data[key].toUpperCase()
    } else {
      upperCased[key] = data[key]
    }
  }
  return upperCased
}

function shortenUrl(arr) {
  let string1 = arr.lastIndexOf("/")
  let string2 = arr.slice(string1 + 1)
  return string2
}
function shortenUrlFiles(arr) {
  let string1 = arr.lastIndexOf("/")
  let string2 = arr.slice(string1 + 1)
  if (string2.length > 15) {
    let newArr = string2.substr(0, 15)
    return newArr + "..."
  }
  return string2
}

function formatDate(date) {
  if (date === "") {
    return ""
  } else {
    return moment(date).format("DD/MM/yyyy")
  }
}

function printPDF(data, height = 400, width = 800) {
  const blob = new Blob([data], {
    type: "application/pdf",
  })
  const fileURL = URL.createObjectURL(blob)
  return window.open(
    fileURL,
    "_blank",
    `location=yes,height=${height},width=${width},scrollbars=yes,status=yes`
  )
}

function titles(imgs) {
  let imgobj = [
    {
      original: process.env.PUBLIC_URL + "/Images/noPhoto.jpg",
    },
  ]
  if (imgs.length > 0) {
    imgobj = imgs.map((item) => ({ original: item }))
  }
  return imgobj
}

const formatter = new Intl.NumberFormat("es-AR", {
  currency: "ARS",
  style: "currency",
  minimumFractionDigits: 0,
})

export {
  TabPanel,
  History,
  shortenUrlFiles,
  isAuthenticated,
  objectValuesToUpperCase,
  shortenUrl,
  checkTokenExpireInsurance,
  formatDate,
  printPDF,
  titles,
  formatter,
}
