import React, { useState } from "react"
import { useFormik } from "formik"

import { ThemeProvider, Grid, Button, Link, TextField } from "@material-ui/core"
import IconButton from "@material-ui/core/IconButton"
import InputAdornment from "@material-ui/core/InputAdornment"
import Visibility from "@material-ui/icons/Visibility"
import VisibilityOff from "@material-ui/icons/VisibilityOff"
import CircularProgress from "@material-ui/core/CircularProgress"
import theme from "Components/Styles"
import useStyles from "../styles"

import ComponentDialog from "../../../Insured/Dialog"

import NewPassword from "Components/UI/Atoms/NewPassword"

const Login = (props) => {
  const classes = useStyles()
  const [view, setView] = useState(false)
  const [loading, setLoading] = useState(false)

  const {
    login,
    validation,
    openNewPassword,
    setOpenNewPassword,
    openDialog,
    setOpenDialog,
    namefirstLabel,
    namesecondLabel,
    firstLabel,
    secondLabel,
  } = props
  const onSubmit = async (values, { resetForm, setErrors, setFieldValue }) => {
    setLoading(true)
    await login(values, resetForm, setErrors, setFieldValue)
    setTimeout(() => {
      setLoading(false)
    }, 1000)
  }

  const handleShowPassword = () => {
    setView(!view)
  }
  const hadnleHidePassword = (event) => {
    event.preventDefault()
  }

  const handleClose = () => {
    setOpenNewPassword(false)
  }

  const handleClose2 = () => {
    setOpenDialog(false)
  }
  const formik = useFormik({
    initialValues: {
      [namefirstLabel]: "",
      [namesecondLabel]: "",
    },
    validationSchema: validation,

    onSubmit: onSubmit,
  })
  return (
    <ThemeProvider theme={theme}>
      {openNewPassword === false ? null : (
        <NewPassword
          open={openNewPassword}
          disabled
          subtitle={
            "Es tu primera vez iniciando sesión, debes cambiar la contraseña obligatoriamente"
          }
          firstLogIn
          handleClose={handleClose}
          historyPush={"/aseguradoras"}
          setOpenNewPassword={setOpenNewPassword}
        />
      )}

      {firstLabel === "Dominio" ? (
        <>
          {openDialog === false ? null : (
            <ComponentDialog
              open={openDialog}
              onClose={handleClose2}
              loading={loading}
            />
          )}
        </>
      ) : null}

      {loading ? (
        <div className={classes.heightContentSpinner}>
          <CircularProgress size={70} />
        </div>
      ) : (
        <form onSubmit={formik.handleSubmit}>
          <Grid container>
            <Grid item sm={12} xs={12}>
              <TextField
                className={classes.FormUpLogin}
                margin="normal"
                fullWidth
                autoFocus={false}
                id={namefirstLabel}
                name={namefirstLabel}
                variant="outlined"
                label={firstLabel}
                value={formik.values[namefirstLabel].trim()}
                inputProps={{
                  maxLength: 25,
                }}
                onChange={formik.handleChange}
                error={
                  formik.touched[namefirstLabel] &&
                  Boolean(formik.errors[namefirstLabel])
                }
                helperText={
                  formik.touched[namefirstLabel] &&
                  formik.errors[namefirstLabel]
                }
                placeholder="Ejemplo: juan"
              />
            </Grid>

            <Grid item sm={12} xs={12}>
              <TextField
                className={classes.FormDownLogin}
                fullWidth
                variant="outlined"
                id={namesecondLabel}
                name={namesecondLabel}
                type={
                  namesecondLabel === "password"
                    ? view
                      ? "text"
                      : "password"
                    : "text"
                }
                label={secondLabel}
                value={formik.values[namesecondLabel].trim()}
                onChange={formik.handleChange}
                error={
                  formik.touched[namesecondLabel] &&
                  Boolean(formik.errors[namesecondLabel])
                }
                helperText={
                  formik.touched[namesecondLabel] &&
                  formik.errors[namesecondLabel]
                }
                placeholder={
                  namesecondLabel === "nro_siniestro"
                    ? "Número de siniestro"
                    : "*********"
                }
                inputProps={{
                  maxLength: 25,
                }}
                InputProps={
                  namesecondLabel === "nro_siniestro"
                    ? null
                    : {
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              onClick={handleShowPassword}
                              onMouseDown={hadnleHidePassword}
                            >
                              {view ? <Visibility /> : <VisibilityOff />}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }
                }
              />
            </Grid>

            <Grid item sm={12} xs={12}>
              <Button
                fullWidth
                variant="contained"
                color="primary"
                type={"submit"}
                className={classes.submit}
                to={"/"}
              >
                {namefirstLabel === "user" ? "Iniciar Sesión" : "Consultar"}
              </Button>
            </Grid>
            {namefirstLabel === "user" ? (
              <Grid item sm={12} xs={12} align="center">
                <Link
                  variant="body2"
                  className={classes.link}
                  onClick={props.onClick}
                  href="#"
                >
                  ¿Olvidó su contraseña?
                </Link>
              </Grid>
            ) : null}
          </Grid>
        </form>
      )}
    </ThemeProvider>
  )
}

export default Login
