import { makeStyles } from "@material-ui/core/styles"
const useStyles = makeStyles((theme) => ({
  "@global": {
    "*::-webkit-scrollbar": {
      width: "0.0em",
    },
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
  root: {
    height: "100vh",
    flexGrow: 1,
  },
  logo: {
    height: "25vh",
    objectFit: "contain",
    maxWidth: "100%",
  },
  divLogo: {
    textAlign: "center",
  },
  colorTexto: {
    fontSize: "2px",
  },
  listItemText: {
    fontSize: "15px",
  },
  lista: {
    padding: "4%",
    zIndex: 999,
    backgroundColor: "#f4f7f4",
    borderRadius: "15px",
  },
  icono: {
    marginRight: "30px",
  },
  listCollapse: {
    left: "10px",
  },
}))
export default useStyles
