import { React, forwardRef, useState } from "react"
import { TextField } from "formik-material-ui"
import { Formik, Field, Form } from "formik"

import { Grid, Typography } from "@material-ui/core"
import IconButton from "@material-ui/core/IconButton"
import InputAdornment from "@material-ui/core/InputAdornment"
import Visibility from "@material-ui/icons/Visibility"
import VisibilityOff from "@material-ui/icons/VisibilityOff"
import { validationNewPassword } from "Components/UI/Validation"
import styles from "./styles"

const FormPassword = forwardRef((props, ref) => {
  const { onSubmit, subtitle } = props

  const [view, setView] = useState(false)
  const classes = styles()

  const handleShowPassword = () => {
    setView(!view)
  }
  const hadnleHidePassword = (event) => {
    event.preventDefault()
  }

  return (
    <Formik
      initialValues={{
        password: "",
        passwordConfirmation: "",
      }}
      validationSchema={validationNewPassword}
      onSubmit={(values) => {
        onSubmit(values)
      }}
    >
      {({ isSubmitting }) => (
        <Form>
          <Grid container spacing={2}>
            <Grid item sm={12} xs={12}>
              <Typography variant="h6" align="center">
                {subtitle}
              </Typography>
            </Grid>
            <Grid item sm={12} xs={12}>
              <Field
                placeholder="*********"
                className={classes.inputs}
                component={TextField}
                fullWidth
                label="Nueva contraseña"
                name="password"
                variant="outlined"
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item sm={12} xs={12}>
              <Field
                placeholder="*********"
                type={view ? "text" : "password"}
                className={classes.inputs}
                component={TextField}
                fullWidth
                name="passwordConfirmation"
                label="Confirme nueva contraseña"
                variant="outlined"
                InputLabelProps={{
                  shrink: true,
                }}
                inputProps={{
                  maxLength: 50,
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={handleShowPassword}
                        onMouseDown={hadnleHidePassword}
                      >
                        {view ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <button
              type="submit"
              disabled={isSubmitting}
              ref={ref}
              style={{ display: "none" }}
            />
          </Grid>
        </Form>
      )}
    </Formik>
  )
})
export default FormPassword
