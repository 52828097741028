import React from "react"

import { Dialog, Grid } from "@material-ui/core"
import Backdrop from "@material-ui/core/Backdrop"
import Fade from "@material-ui/core/Fade"

import Title from "./Title"
import Content from "./Content"
const ComplexDialog = (props) => {
  const {
    listVerticalTab,
    title,
    subtitle,
    subtitle2,
    actions,
    isLoading,
    id,
    open,
    onClose,
    indexStep,
    children,
    setIndexStep,
  } = props
  return (
    <Dialog
      maxWidth="lg"
      fullWidth="true"
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
      open={open}
      onClose={onClose}
      closeAfterTransition
    >
      {isLoading ? null : (
        <div style={{ height: "700px" }}>
          <Fade in={open}>
            <Grid container>
              <Grid item xs={12}>
                <Title
                  title={title}
                  subtitle={subtitle}
                  subtitle2={subtitle2}
                  actions={actions}
                />
              </Grid>
              <Grid item sm={12} xs={12}>
                <Content
                  id={id}
                  indexStep={indexStep}
                  setIndexStep={setIndexStep}
                  children={children}
                  listVerticalTab={listVerticalTab}
                />
              </Grid>
            </Grid>
          </Fade>
        </div>
      )}
    </Dialog>
  )
}

export default ComplexDialog
