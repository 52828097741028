import React, { useContext } from "react"
import {
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Menu,
  Grid,
  ThemeProvider,
} from "@material-ui/core"
import MoreIcon from "@material-ui/icons/MoreVert"
import MenuIcon from "@material-ui/icons/Menu"

import { OpenNavbar } from "Components/Hooks/ContextSideBar"
import { CustomMenuItems } from "Components/UI/Atoms/CustomMenuItems"
import useStyles from "./styles"
import theme from "Components/Styles"

const NavBarGlobal = (props) => {
  const { buttonsMobile, title, subTitle, buttons, subTitle2 } = props

  const classes = useStyles()
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null)
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl)

  const { openSideBar, setOpenSideBar } = useContext(OpenNavbar)

  const handleOpenSideBar = () => {
    setOpenSideBar(!openSideBar)
  }
  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null)
  }

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget)
  }

  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchororigin={{ vertical: "top", horizontal: "right" }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      <CustomMenuItems
        menuItems={buttonsMobile}
        onClose={handleMobileMenuClose}
      />
    </Menu>
  )

  return (
    <div>
      <AppBar
        className={classes.appBar}
        position="absolute"
        style={{ color: "black" }}
        elevation={0}
      >
        <Toolbar>
          <ThemeProvider theme={theme}>
            <IconButton
              className={classes.menuButton}
              onClick={handleOpenSideBar}
            >
              <MenuIcon />
            </IconButton>
            <Typography className={classes.title} variant="h5" noWrap>
              {title}
            </Typography>
            <Typography
              className={classes.title}
              style={{
                color: "#3E984C",
              }}
              variant="h4"
              noWrap
            >
              /
            </Typography>
            <Typography className={classes.subTitle} variant="subtitle2" noWrap>
              {subTitle}
            </Typography>
            {subTitle2 ? (
              <Typography
                className={classes.title}
                style={{
                  color: "#3E984C",
                }}
                variant="h4"
                noWrap
              >
                /
              </Typography>
            ) : null}
            <Typography className={classes.subTitle} variant="subtitle2" noWrap>
              {subTitle2}
            </Typography>
            <div className={classes.grow} />
            {buttons && (
              <>
                <div className={classes.sectionDesktop}>
                  {buttons?.map((item) => (
                    <Grid container justifyContent="flex-end" spacing={2}>
                      <Grid item style={{ padding: "13px" }}>
                        {item}
                      </Grid>{" "}
                    </Grid>
                  ))}
                </div>
                <div className={classes.sectionMobile}>
                  <IconButton
                    aria-haspopup="true"
                    onClick={handleMobileMenuOpen}
                    color="inherit"
                  >
                    <MoreIcon />
                  </IconButton>
                </div>
              </>
            )}
          </ThemeProvider>
        </Toolbar>
      </AppBar>
      {renderMobileMenu}
    </div>
  )
}
export default NavBarGlobal
