import React from "react"
import ReactDOM from "react-dom"
import { Router } from "react-router-dom"
import { SnackbarProvider } from "notistack"

import Grow from "@material-ui/core/Grow"
import CloseIcon from "@material-ui/icons/Close"
import { ThemeProvider } from "@material-ui/core"

import App from "Components/App"
import theme from "Components/Styles"
import { History } from "Helpers"
import OpenNavbarProvider from "Components/Hooks/ContextSideBar"
const root = document.getElementById("root")

ReactDOM.render(
  <SnackbarProvider
    maxSnack={3}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "center",
    }}
    TransitionComponent={Grow}
    action={(key) => <CloseIcon id={key} />}
  >
    <ThemeProvider theme={theme}>
      <OpenNavbarProvider>
        <Router history={History}>
          <App />
        </Router>
      </OpenNavbarProvider>
    </ThemeProvider>
  </SnackbarProvider>,
  root
)
