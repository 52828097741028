import React, { useState, useContext } from "react"
import ComplexDialog from "Components/UI/Molecules/ComplexDialog"
import { FormDataContent } from "Components/Hooks/ContextForm"
import Form from "Components/UI/Organisms/Insured"

const Dialog = (props) => {
  const { open, onClose, loading } = props
  const { initialData } = useContext(FormDataContent)

  const [indexStep, setIndexStep] = useState(0)

  const [listVerticalTab] = useState([
    {
      id: 0,
      title: "Inicio",
      color: "green",
    },
    {
      id: 1,
      title: "Datos",
      color: "green",
    },
    {
      id: 2,
      title: "Retiro",
      color: "green",
    },
  ])

  return (
    <>
      {loading ? null : (
        <ComplexDialog
          open={open}
          onClose={onClose}
          title={initialData.info.dominio}
          subtitle={initialData.info.nro_siniestro}
          subtitle2={initialData.info.asegurado}
          listVerticalTab={listVerticalTab}
          indexStep={indexStep}
          setIndexStep={setIndexStep}
          children={<Form indexStep={indexStep} />}
        />
      )}
    </>
  )
}

export default Dialog
