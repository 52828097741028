import React, { useRef, useState, useEffect } from "react"

import { Grid } from "@material-ui/core"

import DialogComponent from "Components/UI/Molecules/Modals/DialogComponent"
import { Formik, Form } from "formik"
import InputsWithValidate from "Components/UI/Molecules/InputsWithValidate"
import useCustomSnackbar from "Components/UI/Atoms/SnackBar"
import CustomButton from "Components/UI/Atoms/CustomButton"
import ResetPassword from "./ResetPassword"
import { interfaces } from "service/interfaces"
import { fields } from "Components/UI/Organisms/Insurance/Profile/Fields"
import { profileInsurance } from "Components/UI/Validation"

import { setUser, setLastname } from "service/helpers/localstorage"

const Profile = (props) => {
  const { openPerfil, handleClose } = props

  const formSubmitRef = useRef(null)
  const createDismissableSnackbar = useCustomSnackbar()

  const [openDialog, setOpenDialog] = useState(false)
  const [editInsurance, setEditInsurance] = useState({})

  const [editMode, setEditMode] = useState(true)

  function changeEditMode() {
    if (editMode === true) {
      setEditMode(false)
    }
    if (editMode === false) {
      handleSubmit()
    }
  }

  const handleSubmit = () => {
    formSubmitRef.current.click()
  }

  const onSubmit = (values) => {
    const body = {
      nombre: values.nombre,
      apellido: values.apellido,
      email: values.email,
      telefono: parseInt(values.telefono),
    }
    setUser(`"${values.nombre}"`)
    setLastname(`"${values.apellido}"`)
    interfaces.insuranceInterface
      .updateInsuranceProfile(body)
      .then(() => {
        createDismissableSnackbar("Usuario editado correctamente", {
          variant: "success",
        })
        apiCall()
        setEditMode(true)
      })
      .catch((error) => {
        createDismissableSnackbar(error.response.data, {
          variant: "error",
        })
      })
  }

  function apiCall() {
    interfaces.insuranceInterface.insuranceProfile().then((res) => {
      setEditInsurance(res.data)
    })
  }

  useEffect(() => {
    apiCall()
  }, [])

  const onSubmitCancel = () => {
    handleClose()

    setEditMode(true)
    createDismissableSnackbar("Cambios descartados", {
      variant: "success",
    })
  }

  const handleClickOpen = () => {
    setOpenDialog(true)
  }
  const handleClickClose = () => {
    setOpenDialog(false)
  }

  return (
    <>
      <DialogComponent
        tipo="slide"
        open={openPerfil}
        handleclose={handleClose}
        title={`${JSON.parse(localStorage.getItem("cia_seguro"))}`}
        children={
          <>
            <Formik
              onSubmit={(e) => onSubmit(e)}
              initialValues={editInsurance}
              validationSchema={profileInsurance}
              enableReinitialize
            >
              {({ isSubmitting, setFieldValue, values }) => (
                <Form>
                  <InputsWithValidate
                    fields={fields(values, editMode)}
                    setFieldValue={setFieldValue}
                    spacing={1}
                    isSubmitting={isSubmitting}
                  />
                  <button
                    style={{ display: "none" }}
                    type="submit"
                    ref={formSubmitRef}
                  />
                </Form>
              )}
            </Formik>
          </>
        }
        actions={
          <Grid container spacing={1}>
            <Grid item sm={6} xs={6} md={6}>
              <CustomButton
                fullWidth
                onClick={onSubmitCancel}
                text={"Cerrar"}
                disabled={editMode === true ? true : false}
              />
            </Grid>
            <Grid item sm={6} xs={6} md={6}>
              <CustomButton
                color="primary"
                fullWidth
                onClick={changeEditMode}
                text={editMode === true ? "Editar" : "Guardar cambios"}
              />
            </Grid>
            <Grid item sm={12} xs={12}>
              <CustomButton
                color="primary"
                fullWidth
                onClick={handleClickOpen}
                text={"Cambiar contraseña"}
              />
            </Grid>
          </Grid>
        }
      />
      {!openDialog ? null : (
        <ResetPassword open={openDialog} handleClose={handleClickClose} />
      )}
    </>
  )
}

export default Profile
