import React, { createContext, useState } from "react"

// eslint-disable-next-line
export default ({ children }) => {
  const [initialData, setInitialData] = useState({})

  return (
    <FormDataContent.Provider
      value={{
        initialData,
        setInitialData,
      }}
    >
      {children}
    </FormDataContent.Provider>
  )
}

export const FormDataContent = createContext()
