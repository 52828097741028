import React from "react"
import { ThemeProvider, Grid, Button, Typography } from "@material-ui/core"
import useStyles from "../styles"
import theme from "Components/Styles"

const PasswordReseted = (props) => {
  const classes = useStyles()
  return (
    <ThemeProvider theme={theme}>
      <form className={classes.form} noValidate>
        <Grid container>
          <Grid item sm={12}>
            <Typography align="center" className={classes.TextFormBack}>
              Hemos enviado su nueva contraseña al correo electrónico ingresado.
              Vuelva al menú anterior y ingrese con la nueva contraseña.
            </Typography>
          </Grid>
          <Grid item sm={12}>
            <div className={classes.FormFormBack}></div>
          </Grid>

          <Grid item sm={12} xs={12}>
            <Button
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
              onClick={props.onClick}
            >
              Volver
            </Button>
          </Grid>
          <Grid item sm={12} justify="center">
            <div className={classes.FormButtonBack}></div>
          </Grid>
        </Grid>
      </form>
    </ThemeProvider>
  )
}

export default PasswordReseted
