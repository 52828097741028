import React from "react"
import PropTypes from "prop-types"

import AppBar from "@material-ui/core/AppBar"
import Tabs from "@material-ui/core/Tabs"
import Tab from "@material-ui/core/Tab"

const a11yProps = (index) => {
  return {
    id: `scrollable-auto-tab-${index}`,
    "aria-controls": `scrollable-auto-tabpanel-${index}`,
  }
}

const DialogTabs = (props) => {
  const { listVerticalTab, children, setIndexStep, indexStep } = props
  const handleChange = (event, newValue) => {
    setIndexStep(newValue)
  }
  return (
    <div>
      <AppBar position="relative" color="default">
        <Tabs
          value={indexStep}
          onChange={handleChange}
          indicatorColor="primary"
          variant="scrollable"
          scrollButtons="on"
          aria-label="scrollable auto tabs example"
        >
          {listVerticalTab.map((item, index) => (
            <Tab
              style={{ color: item.color }}
              label={item.title}
              {...a11yProps(index)}
            />
          ))}
        </Tabs>
      </AppBar>

      {children}
    </div>
  )
}

export default DialogTabs

DialogTabs.propTypes = {
  handleChange: PropTypes.func,
}
