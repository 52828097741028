import { makeStyles } from "@material-ui/core"

const useStyles = makeStyles((theme) => ({
  dialogPass: {
    width: "auto",
    height: "auto",
  },

  btnDialog: {
    margin: "10px",
    width: "99px",
    height: "36px",
    fontWeight: "500",
    justifyContent: "flex",
  },
  dialogContent: {
    width: "450px",
  },
  inputDialog: {
    width: "377px",
    height: "56px",
    borderRadius: "4px",
  },
  dialogActions: {
    width: "450px",
  },
  dialogTitte: {
    width: "450px",
    height: "auto",
  },
  btnCansel: {
    background: "transparent",
    margin: "10px",
    width: "99px",
    height: "36px",
    fontWeight: "500",
    justifyContent: "flex",
    borderShadow: "0px",
  },
  inputs: {
    height: "70px",
  },
}))
export default useStyles
