import { createTheme } from "@material-ui/core"

export const getMuiTheme = (columnTheme) => {
  return createTheme({
    palette: {
      primary: {
        main: "rgba(62,152,76,1)",
      },
      secondary: {
        light: "#ff7961",
        main: "#f44336",
        dark: "#ba000d",
      },
    },
    overrides: {
      MUIDataTableHeadCell: {
        root: columnTheme,
        sortAction: {
          "& path": {
            color: "rgba(62,152,76,1)",
          },
        },
        sortActive: {
          color: "rgba(62,152,76,1)",
        },
      },
      MUIDataTableBodyCell: {
        root: {
          color: "#000",
          fontWeight: 400,
          whiteSpace: "pre-wrap",
          textOverflow: "ellipsis",
        },
      },
      MUIDataTableToolbar: {
        root: {
          background:
            columnTheme && columnTheme.toolbarBackground
              ? columnTheme.toolbarBackground
              : "linear-gradient(90deg, rgba(62,152,76,1) 35%, rgba(0,134,126,1) 100%)",
          color: "white",
        },
        icon: {
          color:
            columnTheme && columnTheme.toolbarBackground
              ? "rgba(62,152,76,1)"
              : "white",
          "&:hover": {
            color:
              columnTheme && columnTheme.toolbarBackground
                ? "rgba(62,152,76,1)"
                : "white",
          },
        },
      },
      MUIDataTableSearch: {
        root: {
          backgroundColor: "white",
          color: "white",
          "&:active": {
            color: "white",
          },
        },
        searchIcon: {
          color: "white",
          "&:active": {
            color: "white",
          },
        },
        searchProps: {
          color: "white",
        },
        clearIcon: {
          color: "white",
        },
        searchText: {
          color: "white",
        },
      },
      MUIDataTableFilter: {
        color: "red",
      },
      MuiTableRow: {
        root: {
          width: "500px",
        },
      },
      MUIDataTable: {
        paper: {
          height: "inherit",
        },
        responsiveScroll: {
          maxHeight: "none",
          height: "calc(100% - 128px)",
        },
      },
    },
  })
}
