export const styles = {
  dialogTitle: {
    paddingBottom: 0,
  },
  root: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  subtitle: {
    marginBottom: "15px",
  },
}
