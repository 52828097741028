import * as yup from "yup"

export const validationLogin = yup.object({
  user: yup
    .string()
    .matches(/[a-zA-Z]/, "Debe contener solo letras")
    .required("Este campo es obligatorio")
    .min(4, "Debe ingresar al menos 4 caracteres ")
    .max(24, "Debe ingresar menos de 24 caracteres"),
  password: yup
    .string()
    .min(6, "Debe ingresar al menos 6 caracteres")
    .max(25, "Debe ingresar menos de 25 caracteres")
    .required("Este campo es obligatorio"),
})

export const validationInsured = yup.object({
  dominio: yup
    .string()
    .required("Este campo es obligatorio")
    .min(6, "Debe ingresar al menos 6 caracteres ")
    .max(7, "Debe ingresar menos de 7 caracteres"),
  nro_siniestro: yup
    .string()
    .required("Este campo es obligatorio")
    .max(25, "Debe ingresar menos de 25 caracteres"),
})

export const validationRemovePass = yup.object({
  username: yup
    .string()
    .min(4, "Usuario demasiado corto")
    .max(20, "Usuario demasiado largo")
    .required("Usuario requerido"),
})

export const validationNewPassword = yup.object({
  password: yup
    .string()
    .min(6, "Al menos 6 caracteres")
    .required("Este campo es obligatorio")
    .when("oldPassword", (oldPassword, field) =>
      oldPassword ? field.required() : field
    ),
  passwordConfirmation: yup
    .string()
    .min(6, "Al menos 6 caracteres")
    .oneOf([yup.ref("password"), null], "La contraseña no coincide")
    .required("Este campo es obligatorio"),
})
export const validationInputNewTransit = yup.object({
  dominio: yup
    .string()
    .required("Este campo es obligatorio")
    .max(9, "No puedes escribir mas de 9 caracteres"),
})

export const validationRemoveInsured = yup.object({
  email: yup.string().required("Email Requerido"),
})

export const profileInsurance = yup.object({
  email: yup
    .string()
    .required("Email Requerido")
    .email("Debe ser un email valido"),
  telefono: yup
    .string()
    .required("Telefono Requerido")
    .max(11, "No ingrese mas de 11 digitos"),
})
