import { makeStyles } from "@material-ui/core"

export const useStyles = makeStyles(() => ({
  buttons: {
    width: "100%",
    display: "flex",
    justifyContent: "flex-end",
    marginRight: "15px",
    marginBottom: "10px",
  },
  textType: {
    textTransform: "initial",
  },
}))
