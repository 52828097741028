import React, { useContext, useState } from "react"
import Timeline from "@material-ui/lab/Timeline"
import TimelineItem from "@material-ui/lab/TimelineItem"
import TimelineSeparator from "@material-ui/lab/TimelineSeparator"
import TimelineConnector from "@material-ui/lab/TimelineConnector"
import TimelineContent from "@material-ui/lab/TimelineContent"
import TimelineDot from "@material-ui/lab/TimelineDot"
import CustomButton from "Components/UI/Atoms/CustomButton"
import { FormDataContent } from "Components/Hooks/ContextForm"
import WhatsAppIcon from "@material-ui/icons/WhatsApp"
import { Box, Typography, Grid } from "@material-ui/core"
import { TextField } from "@material-ui/core"
import useCustomSnackbar from "Components/UI/Atoms/SnackBar"

import DialogComponent from "Components/UI/Molecules/Modals/DialogComponent"
import Rating from "@material-ui/lab/Rating"
import { interfaces } from "service/interfaces"

import CancelConfirmButtons from "Components/UI/Molecules/CancelConfirmButtons"
import { Formik, Form } from "formik"
import { formatDate } from "Helpers/index"

const TimeLine = () => {
  const { initialData } = useContext(FormDataContent)
  const createDismissableSnackbar = useCustomSnackbar()
  const [open, setOpen] = useState(false)
  const [values, setValue] = useState({
    estrellas: 0,
    comentarios: "",
  })

  const OBJETO = [
    {
      text: "Vehículo adjudicado",
      color: "primary",
      secondText: formatDate(initialData.dates.fecha_adjudicacion),
    },
    {
      text: "Fecha pactada de retiro",
      secondText: formatDate(initialData.dates.fecha_retiro),
    },
    {
      text: "Ingreso al desarmadero",
      secondText: formatDate(initialData.dates.fecha_ingreso),
    },
    {
      text: "Envío de documentación",
      secondText: formatDate(initialData.dates.fecha_adjudicacion),
    },
    {
      text: "Ingreso de la baja",
      secondText: formatDate(initialData.dates.fecha_baja),
    },
    {
      text: "Finalización trámite DNRPA",
      secondText: formatDate(initialData.dates.fecha_tramite),
    },
  ]

  const text = `Hola, mi nombre es Jesica Herrera, me comunico de la empresa ECO Autoparts. Tu compañía de seguros ${initialData.info.cia_seguros} nos ha asignado a nosotros para gestionar el retiro de tu siniestro Nº *${initialData.info.nro_siniestro}*, dominio *${initialData.info.dominio}*.Nos agrada comunicarle que poseemos una plataforma exclusiva en la cual podrás hacer un seguimiento de toda nuestra gestión, desde el retiro de tu vehículo hasta la gestión de la baja del vehículo.Para ingresar, deberás acceder al siguiente link: https://app.ecoautoparts.com.ar/asegurado y con los siguientes accesos: Nº siniestro: *${initialData.info.nro_siniestro}* Dominio: *${initialData.info.dominio}* Podrás ingresar y realizar el seguimiento. Muchas gracias por confiar en ECO Autoparts! No olvides de calificar nuestro servicio dentro del link otorgado, muchas gracias!`

  const handleChange = (e) => {
    setValue({
      ...values,
      [e.target.name]: e.target.value,
    })
  }

  const openModal = () => {
    setOpen(true)
  }

  const closeModal = () => {
    setOpen(false)
  }

  const onSubmit = async () => {
    const body = {
      estrellas: parseInt(values.estrellas),
      comentarios: values.comentarios,
    }

    await interfaces.insuranceInterface
      .qualification(initialData.info.id, body)
      .then((res) => {
        createDismissableSnackbar(res.data, {
          variant: "success",
        })
        closeModal()
        setValue({})
      })
      .catch((error) =>
        createDismissableSnackbar(error.response.data, {
          variant: "error",
        })
      )
  }

  const children = () => {
    return (
      <Formik onSubmit={onSubmit} initialValues={values} enableReinitialize>
        {({ isSubmitting, handleSubmit, resetForm }) => (
          <Form onSubmit={handleSubmit}>
            <Grid container spacing={2}>
              <Grid item sm={12} xs={12} style={{ padding: "0px" }}>
                <Box component="fieldset" mb={3} borderColor="transparent">
                  <Typography
                    component="legend"
                    style={{ textAlign: "center" }}
                  >
                    Estrellas
                  </Typography>
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <Rating
                      name="estrellas"
                      value={values.estrellas}
                      onChange={handleChange}
                      size="large"
                    />
                  </div>
                </Box>
              </Grid>
              <Grid item sm={12} xs={12}>
                <TextField
                  name="comentarios"
                  placeholder="Comentanos sobre nuestro servicio"
                  variant="outlined"
                  value={values.comentarios}
                  onChange={handleChange}
                  multiline
                  rows={5}
                  rowsMax={5}
                  fullWidth
                />
              </Grid>
              <Grid item sm={12} xs={12}>
                <CancelConfirmButtons
                  onClickCancel={closeModal}
                  disabledSubmit={isSubmitting}
                />
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    )
  }

  return (
    <>
      <Timeline align="alternate" style={{ height: "500px" }}>
        {OBJETO.map((item) => (
          <>
            <TimelineItem>
              <TimelineSeparator>
                <TimelineDot color={item.color} />
                <TimelineConnector />
              </TimelineSeparator>
              <TimelineContent>
                {item.text} <strong>{item.secondText}</strong>
              </TimelineContent>
            </TimelineItem>
          </>
        ))}
      </Timeline>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          paddingRight: "20px",
        }}
      >
        <div
          style={{
            paddingRight: "10px",
          }}
        >
          <CustomButton
            text={"Califícanos!"}
            color="primary"
            onClick={openModal}
          />
        </div>

        <div>
          <CustomButton
            href={`https://wa.me/5493584367392?text=${text}`}
            class="whatsapp_float"
            target="_blank"
            rel="noopener noreferrer"
            text={"Gestoría"}
            color="primary"
            endIcon={<WhatsAppIcon />}
            disabled={true}
          />
        </div>
      </div>
      <DialogComponent
        open={open}
        handleclose={closeModal}
        title="¿Qué te pareció nuestro servicio?"
        children={children()}
      />
    </>
  )
}

export default TimeLine
