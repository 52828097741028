import React, { useState } from "react"

import { Tooltip, makeStyles } from "@material-ui/core"
import CheckCircleIcon from "@material-ui/icons/CheckCircle"
import CancelIcon from "@material-ui/icons/Cancel"
import useMediaQuery from "@material-ui/core/useMediaQuery"

import ModalTableInsurance from "Components/UI/Organisms/Insurance/DialogInsurance/index"
import MuiDatables from "Components/UI/Molecules/Table"
import SetState from "Components/Hooks/SetState"
import AutocompleteFilter from "Components/UI/Molecules/Table/Autocomplete"
import columnsForTable from "Components/UI/Molecules/Table/Columns"
import { columnTheme } from "Components/UI/Organisms/Insurance/Table/styles"
import { interfaces } from "service/interfaces"
import { formatter } from "Helpers"

const TableInsurance = (props) => {
  const { render, setRender } = props
  const { state, setOpen, setClose } = SetState()
  const windowSize = useMediaQuery("(min-width:1000px)")

  const [info, setInfo] = useState({
    filters: [],
    rows: [],
    header: [],
  })

  async function apiCall(options, callback) {
    setInfo({
      filters: [],
      rows: [],
      header: [],
    })
    try {
      const result = await interfaces.insuranceInterface.listOfSinister(options)
      setInfo({
        filters: result.data.filters,
        rows: result.data.data,
        header: result.header.header.count,
      })
      callback()
    } catch (error) {}
  }

  async function openDialog(id) {
    await interfaces.insuranceInterface.allInfoOfSinister(id).then((res) => {
      setOpen({ infoDialog: res.data })
    })
  }

  const tooltipsStates = (obleas, fecha_obleas, fecha_tramite) => {
    // eslint-disable-next-line default-case
    if (fecha_tramite && fecha_obleas)
      return <div style={{ display: "flex" }}>{fecha_obleas}</div>
    if (!fecha_tramite && !fecha_obleas)
      return <div style={{ display: "flex" }}></div>
    switch (obleas > 0) {
      case true:
        return (
          <div style={{ display: "flex" }}>
            {fecha_obleas}&nbsp;&nbsp;
            <Tooltip title={`Dias restantes: ${obleas}`}>
              <CheckCircleIcon style={{ color: "green" }} />
            </Tooltip>
          </div>
        )
      case false:
        return (
          <div style={{ display: "flex" }}>
            {fecha_obleas}&nbsp;&nbsp;
            <Tooltip title={`VENCIDO`}>
              <CancelIcon style={{ color: "red" }} />
            </Tooltip>
          </div>
        )
      default:
        return
    }
  }

  const useStyles = makeStyles((theme) => ({
    textFieldMaterial: {
      width: "300px",
      [theme.breakpoints.down("sm")]: {
        width: "200px",
      },
    },
  }))
  const classes = useStyles()

  const RowsVehicles = () =>
    info.rows.map((file) => [
      "",
      file.nro_siniestro,
      file.dominio,
      file.tipo,
      file.vehiculo,
      file.cotizacion_restos ? formatter.format(file.cotizacion_restos) : "",
      file.fecha_retiro,
      tooltipsStates(file.obleas, file.fecha_obleas, file.fecha_tramite),
      "",
      "",
    ])

  const columns = columnsForTable(
    [
      {
        name: "nro_siniestro",
        label: "Siniestro",
        display: true,
        filter: false,
        sort: true,
        customBodyRender: true,
      },
      {
        name: "dominio",
        label: "Dominio",
        display: true,
        filter: false,
        sort: true,
        customBodyRender: true,
      },
      {
        name: "tipo",
        label: "Tipo",
        display: true,
        filter: false,
        sort: false,
      },
      {
        name: "vehiculo",
        label: "Vehículo",
        display: true,
        filter: false,
        sort: false,
      },
      {
        name: "cotizacion_restos",
        label: "Monto de cotización",
        display: true,
        filter: false,
        sort: false,
      },
      {
        name: "fecha_retiro",
        label: "Fecha de retiro",
        display: true,
        filter: false,
        sort: false,
      },
      {
        name: "fecha_obleas",
        label: "Asignación obleas",
        display: true,
        filter: false,
        sort: false,
      },
      {
        name: "marca",
        label: "Marca",
        display: false,
        filter: true,
        sort: false,
        advancedOptions: {
          display: (filterList, onChange, index, column) => {
            if (Object.keys(info.filters).length > 0) {
              return (
                <AutocompleteFilter
                  style={classes.textFieldMaterial}
                  fullWidth={true}
                  filterList={filterList}
                  onChange={onChange}
                  index={index}
                  column={column}
                  label={"Marca"}
                  filterName={"marca"}
                  noOptionsText={"Seleccione una marca primero"}
                  options={info.filters.marcas}
                />
              )
            }
          },
          fullWidth: true,
        },
      },
      {
        name: "modelo",
        label: "Modelo",
        display: false,
        filter: true,
        sort: false,
        advancedOptions: {
          display: (filterList, onChange, index, column) => {
            if (Object.keys(info.filters).length > 0) {
              return (
                <AutocompleteFilter
                  style={classes.textFieldMaterial}
                  fullWidth={true}
                  filterList={filterList}
                  onChange={onChange}
                  index={index}
                  column={column}
                  label={"Modelo"}
                  filterName={"modelo"}
                  noOptionsText={"Seleccione una marca primero"}
                  options={info.filters.modelos.filter((item) => {
                    return item.marca.marca === filterList[8][0]
                  })}
                />
              )
            }
          },
          fullWidth: true,
        },
      },
    ],
    info.filters
  )

  return (
    <>
      <MuiDatables
        columns={columns}
        data={RowsVehicles()}
        title={`Cantidad de siniestros: ${info.header}`}
        apiCall={apiCall}
        count={info.header}
        textPlaceholder="Buscar por dominio o número de siniestro…"
        render={render}
        setRender={setRender}
        columnTheme={columnTheme()}
        onRowClick={(_, index) => openDialog(info.rows[index.dataIndex].id)}
        rows={50}
        padding={windowSize ? "10px" : ""}
      />
      {state.suspense && (
        <ModalTableInsurance
          open={state.open}
          info={state.infoDialog}
          state={state.infoDialog}
          onClose={setClose}
          setRenderTable={setRender}
          renderTable={render}
        />
      )}
    </>
  )
}
export default TableInsurance
