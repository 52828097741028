import React from "react"

import AutocompleteFilter from "Components/UI/Molecules/Table/Autocomplete"
import { Tooltip } from "@material-ui/core"

const columnsForTable = (data, filters) => {
  const parentStyle = {
    position: "absolute",
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    boxSizing: "border-box",
    display: "block",
    width: "100%",
  }
  const cellStyle = {
    boxSizing: "border-box",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  }
  const emptyColumn = {
    name: " ",
    label: "",
    display: false,
    filter: false,
    sort: false,
  }
  const array = [emptyColumn, ...data]
  return array.map((item) => ({
    name: item.name,
    label: item.label,
    options: {
      display: item.display,
      filter: item.filter,
      sort: item.sort,
      sortThirdClickReset: true,
      filterType: "custom",
      customFilterListOptions: {
        render: (v) => `${item.label}: ${v}`,
      },
      customBodyRender: (val) => {
        if (item.customBodyRender) {
          return (
            <div
              style={{
                position: "relative",
                height: "20px",
                marginLeft: item.marginLeft ? item.marginLeft : "",
              }}
            >
              <Tooltip title={val}>
                <div style={parentStyle}>
                  <div style={cellStyle}>{val}</div>
                </div>
              </Tooltip>
            </div>
          )
        } else {
          return val
        }
      },
      filterOptions: item.advancedOptions
        ? item.advancedOptions
        : {
            display: (filterList, onChange, index, column) => {
              if (item.filter) {
                if (Object.keys(filters).length > 0) {
                  return (
                    <AutocompleteFilter
                      style={item.style}
                      filterList={filterList}
                      onChange={onChange}
                      index={index}
                      column={column}
                      label={item.label === " " ? item.filterLabel : item.label}
                      filterName={item.filterName}
                      options={item.options}
                      noOptionsText={
                        item.noOptionsText
                          ? item.noOptionsText
                          : "No se encontraron resultados"
                      }
                    />
                  )
                }
              }
            },
            fullWidth: item.fullWidth,
          },
    },
  }))
}

export default columnsForTable
