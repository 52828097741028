import React from "react"

import { Box } from "@material-ui/core"
import { useWindowSize } from "react-use"
import useStyles from "./styles"

const TemplateGlobal = (props) => {
  const { navbar, table } = props

  const { width, height } = useWindowSize()

  const classes = useStyles({ width, height })
  return (
    <>
      <Box className={classes.navbar}>{navbar}</Box>
      <div className={classes.table}>{table}</div>
    </>
  )
}

export default TemplateGlobal
