import { instances } from "./instances"
import {
  setToken,
  deleteToken,
  deleteUser,
  setUser,
  setUserId,
  deleteUserId,
  setFirstLogIn,
  deleteFirstLogIn,
  deteleExpireTokenDate,
  setExpireTokenDate,
  setProfileThumbnail,
  deleteProfileThumbnail,
  setUbicacion,
  deleteUbicacion,
  setInsurance,
  setLastname,
} from "./helpers/localstorage"
import { handleSessionStorage } from "./helpers/sessionstorage"
import { checkTokenExpireInsurance } from "Helpers"
import { insuranceEndpoints } from "./sources/insurance"

const headers = {
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
  },
}

function get(endpoint) {
  checkTokenExpireInsurance()
  return instances.instanceCommon
    .get(endpoint, headers)
    .then((res) => {
      if (res.status === 200 || res.status === 201) {
        return res
      } else {
        return Promise.reject(res)
      }
    })
    .then((res) => {
      let keyPath = res.config.baseURL + res.config.url
      handleSessionStorage.removeItem(keyPath)
      handleSessionStorage.setItem(keyPath, JSON.stringify(res))
      return Promise.resolve(res.data)
    })
    .catch((err) => {
      return Promise.reject(err)
    })
}

function del(endpoint) {
  checkTokenExpireInsurance()
  return instances.instanceCommon
    .delete(endpoint, headers)
    .then((res) => {
      if (res.status === 200 || res.status === 201 || res.status === 204) {
        return res
      } else {
        return Promise.reject(res)
      }
    })
    .then((res) => {
      let keyPath = res.config.baseURL + res.config.url
      handleSessionStorage.removeItem(keyPath)
      handleSessionStorage.setItem(keyPath, JSON.stringify(res))
      return Promise.resolve(res.data)
    })
    .catch((err) => {
      return Promise.reject(err)
    })
}

function post(endpoint, body) {
  checkTokenExpireInsurance()
  return instances.instanceCommon
    .post(endpoint, body, headers)
    .then((res) => {
      if (res.status === 200 || res.status === 201 || res.status === 204) {
        return res
      } else {
        return Promise.reject(res)
      }
    })
    .then((res) => {
      let keyPath = res.config.baseURL + res.config.url
      handleSessionStorage.removeItem(keyPath)
      handleSessionStorage.setItem(keyPath, JSON.stringify(res))
      return Promise.resolve(res.data)
    })
    .catch((err) => {
      return Promise.reject(err)
    })
}

function postWithoutAuth(endpoint, body) {
  return instances.instanceCommon
    .post(endpoint, body, headers)
    .then((res) => {
      if (res.status === 200 || res.status === 201 || res.status === 204) {
        return res
      } else {
        return Promise.reject(res)
      }
    })
    .then((res) => {
      let keyPath = res.config.baseURL + res.config.url
      handleSessionStorage.removeItem(keyPath)
      handleSessionStorage.setItem(keyPath, JSON.stringify(res))
      return Promise.resolve(res.data)
    })
    .catch((err) => {
      return Promise.reject(err)
    })
}

function put(endpoint, body) {
  checkTokenExpireInsurance()
  return instances.instanceCommon
    .put(endpoint, body, headers)
    .then((res) => {
      if (res.status === 200 || res.status === 201 || res.status === 204) {
        return res
      } else {
        return Promise.reject(res)
      }
    })
    .then((res) => {
      let keyPath = res.config.baseURL + res.config.url
      handleSessionStorage.removeItem(keyPath)
      handleSessionStorage.setItem(keyPath, JSON.stringify(res))
      return Promise.resolve(res.data)
    })
    .catch((err) => {
      return Promise.reject(err)
    })
}

function authLogin(endopoint, username, password, geo) {
  const postOptions = {
    method: "POST",
    data: {
      username,
      password,
      geo,
    },
    headers: {
      "Content-Type": "application/json",
    },
  }
  return instances
    .instanceCommon(endopoint, postOptions)
    .then((res) => {
      if (res.data.firstLogIn) {
        setToken(res.data.access_token)
        return res
      } else {
        if (res.data && res.data.access_token) {
          setUserId(res.data.user_id)
          setToken(res.data.access_token)
          setUser(JSON.stringify(res.data.user))
          setFirstLogIn(res.data.firstLogIn)
          setExpireTokenDate(res.data.expireAt)
          setProfileThumbnail(res.data.profile)
          setUbicacion(res.data.ubicacion)
          setInsurance(JSON.stringify(res.data.cia_seguro))
          setLastname(JSON.stringify(res.data.lastname))
          return res
        } else {
          return Promise.reject("Error: response result not found")
        }
      }
    })
    .then((res) => {
      let keyPath = res.config.baseURL + res.config.url
      handleSessionStorage.removeItem(keyPath)
      handleSessionStorage.setItem(keyPath, JSON.stringify(res))
      return Promise.resolve(res.data)
    })
    .catch((error) => Promise.reject(error))
}

function refreshTokenInsurance(id) {
  const postOptions = {
    method: "POST",
    data: {
      id,
    },
    headers: {
      "Content-Type": "application/json",
    },
  }
  return instances
    .instanceCommon(insuranceEndpoints.REFRESH_TOKEN_INSURANCE, postOptions)
    .then((res) => {
      if (res.data && res.data.access_token) {
        setToken(res.data.access_token)
        setExpireTokenDate(res.data.expireAt)
        return res
      } else {
        return Promise.reject("Error: response result not found")
      }
    })
    .then((res) => {
      let keyPath = res.config.baseURL + res.config.url
      handleSessionStorage.removeItem(keyPath)
      handleSessionStorage.setItem(keyPath, JSON.stringify(res))
      return Promise.resolve(res.data.user)
    })
    .catch((error) => Promise.reject(error))
}

function getPDF(endpoint, body) {
  checkTokenExpireInsurance()
  const postOptions = {
    method: "POST",
    responseType: "blob",
    data: body,
  }
  return instances
    .instanceCommon(endpoint, postOptions)
    .then((res) => {
      if (res.status === 200 || res.status === 201 || res.status === 204) {
        return res
      } else {
        return Promise.reject(res)
      }
    })
    .then((res) => {
      return Promise.resolve(res.data)
    })
    .catch((err) => {
      return Promise.reject(err)
    })
}

function logout() {
  deleteToken()
  deleteUser()
  deleteFirstLogIn()
  deteleExpireTokenDate()
  deleteUserId()
  deleteProfileThumbnail()
  deleteUbicacion()
}

export const axiosService = {
  get,
  post,
  postWithoutAuth,
  put,
  del,
  authLogin,
  refreshTokenInsurance,
  logout,
  getPDF,
}
