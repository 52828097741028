import React from "react"

import FormDataContent from "Components/Hooks/ContextForm"

import ContainerInsured from "./ContainerInsured"

const Insured = () => {
  return (
    <FormDataContent>
      <ContainerInsured />
    </FormDataContent>
  )
}

export default Insured
