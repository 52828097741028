import React from "react"
import { Field } from "formik"

import { TextField } from "formik-material-ui"
import { Grid } from "@material-ui/core"

import { FormikAutocomplete } from "Components/UI/Atoms/CustomAutocomplete"
import useStyles from "./styles"

export default function InputsWithValidate(props) {
  const { spacing, fields, setFieldValue, isSubmitting } = props
  const classes = useStyles()
  return (
    <div>
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        alignContent="center"
        spacing={spacing}
      >
        {fields.map((field, index) => {
          if (field.class === "textfield") {
            if (field.onChange) {
              return (
                <Grid item sm={field.sm} key={index} xs={field.xs}>
                  <Field
                    component={TextField}
                    disabled={field.disabled}
                    multiline={field.multiline}
                    rows={field.rows}
                    type={field.type}
                    style={field.style}
                    className={classes.heightsForm}
                    label={field.label}
                    name={field.name}
                    initialValue={field.value}
                    onChange={field.onChange}
                    variant="outlined"
                    fullWidth
                    value={field.value}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    inputProps={field.inputProps}
                    autoFocus={field.autoFocus}
                  />
                </Grid>
              )
            } else {
              return (
                <Grid item sm={field.sm} key={index} xs={field.xs}>
                  <Field
                    component={TextField}
                    disabled={field.disabled}
                    type={field.type}
                    style={field.style}
                    multiline={field.multiline}
                    rows={field.rows}
                    className={classes.heightsForm}
                    label={field.label}
                    name={field.name}
                    initialValue={field.value}
                    variant="outlined"
                    fullWidth
                    value={field.value}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    inputProps={field.inputProps}
                    rowsMax={field.rowsMax}
                    autoFocus={field.autoFocus}
                  />
                </Grid>
              )
            }
          }
          if (field.class === "autocomplete") {
            return (
              <Grid item sm={field.sm} xs={field.xs}>
                <FormikAutocomplete
                  freeSolo={field.freeSolo}
                  disabled={isSubmitting ? true : field.disabled}
                  defaultValue={field.defaultValue}
                  disableClearable={field.disableClearable}
                  name={field.name}
                  options={field.options}
                  style={field.style}
                  clearOnEscape={field.clearOnEscape}
                  getOptionDisabled={field.getOptionDisabled}
                  // eslint-disable-next-line
                  getOptionLabel={(options) => eval(field.optionLabel)}
                  noOptionsText={field.noOptionsText}
                  endAdornment={field.endAdornment}
                  label={field.label}
                  onChange={(_, value, reason) => {
                    if (field.onChange) {
                      field.onChange(value, reason)
                    } else {
                      setFieldValue(field.name, value[field.optionValue])
                    }
                  }}
                  onInputChange={(_, value) => {
                    if (field.onInputChange) {
                      field.onInputChange(value)
                    } else {
                      setFieldValue(field.name, value)
                    }
                  }}
                  sortBy={field.sortBy}
                  value={field.options.find(
                    (index) => index[field.optionValue] === field.value
                  )}
                  groupBy={(options) => options[field.groupBy]}
                  onKeyDown={field.onKeyDown}
                />
              </Grid>
            )
          }
          if (field.class === "custom") {
            return (
              <Grid item sm={field.sm} xs={field.xs}>
                {field.component}
              </Grid>
            )
          }
          return ""
        })}
      </Grid>
    </div>
  )
}
