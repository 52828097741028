const INSURANCE_RESET_PASSWORD = "/insurance/reset"
const INSURANCE_PROFILE = "/insurance"
const INSURANCE_LOGIN = "/login/insurance"
const UPDATE_INSURANCE_PROFILE = "/insurance"
const LIST_OF_SINISTER = "/insurance/list"
const ALL_INFO_SINISTER = "/insurance/allinfo"
const RESET_PASSWORD_LOGIN = "/login/insurancerereset"
const RESET_PASSWORD_HOME = "/insurance/reset"
const REFRESH_TOKEN_INSURANCE = "/login/refreshtoken"
const INSURED_INFO = "/insured/insured"
const QUALIFICATION = "/insured/insuredqualification"
const EXPORT_DATA = "/insurance/export"
const PRINT = "/insurance/print"

export const insuranceEndpoints = {
  INSURANCE_RESET_PASSWORD,
  INSURANCE_PROFILE,
  INSURANCE_LOGIN,
  UPDATE_INSURANCE_PROFILE,
  LIST_OF_SINISTER,
  ALL_INFO_SINISTER,
  RESET_PASSWORD_LOGIN,
  RESET_PASSWORD_HOME,
  REFRESH_TOKEN_INSURANCE,
  INSURED_INFO,
  QUALIFICATION,
  EXPORT_DATA,
  PRINT,
}
