import React, { useState } from "react"
import { useFormik } from "formik"
import { Link } from "react-router-dom"

import { ThemeProvider, Button, Typography, TextField } from "@material-ui/core"
import { Grid } from "@material-ui/core"
import CircularProgress from "@material-ui/core/CircularProgress"

import useStyles from "../styles"
import theme from "Components/Styles"
import { validationRemoveInsured } from "Components/UI/Validation"
import { interfaces } from "service/interfaces"

const RemovePass = (props) => {
  const { onClick } = props
  const [loading, setLoading] = useState(false)

  const onSubmit = async (values, { resetForm, setErrors, setFieldValue }) => {
    setLoading(true)
    await interfaces.insuranceInterface
      .resetPasswordLogin(values)
      .then((res) => {
        resetForm({})
        onClick()
      })
      .catch((error) => {
        setErrors({
          email:
            error.response.data.data === "Usuario no encontrado"
              ? "Cuenta no encontrada"
              : null,
        })
        setFieldValue("email", "", null)
      })
    setTimeout(() => {
      setLoading(false)
    }, 1000)
  }

  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: validationRemoveInsured,
    onSubmit: onSubmit,
  })

  const classes = useStyles()
  return (
    <ThemeProvider theme={theme}>
      {loading ? (
        <div className={classes.heightContentSpinner}>
          <CircularProgress size={70} />
        </div>
      ) : (
        <form onSubmit={formik.handleSubmit}>
          <Grid container>
            <Grid item sm={12}>
              <Typography align="center" className={classes.TextRemovePass}>
                Ingresá el correo electrónico asociado a tu cuenta y te
                enviaremos una nueva contraseña para acceder.
              </Typography>
            </Grid>
            <Grid item sm={12} xs={12}>
              <TextField
                type="email"
                className={classes.FormRemovePass}
                margin="normal"
                fullWidth
                autoFocus={false}
                variant="outlined"
                id="email"
                name="email"
                label={"Correo electronico"}
                value={formik.values.email}
                inputProps={{
                  maxLength: 25,
                }}
                onChange={formik.handleChange}
                error={formik.touched.email && Boolean(formik.errors.email)}
                helperText={formik.touched.email && formik.errors.email}
              />
            </Grid>
            <Grid item sm={12} xs={12}>
              <Button
                fullWidth
                variant="contained"
                color="primary"
                type={"submit"}
                className={classes.submit}
              >
                REINICIAR CONTRASEÑA
              </Button>
            </Grid>

            <Grid container item justify="center">
              <Link
                to={"/"}
                variant="body2"
                className={classes.link}
                onClick={props.onClickVolver}
              >
                Volver
              </Link>
            </Grid>
          </Grid>
        </form>
      )}
    </ThemeProvider>
  )
}

export default RemovePass
