import React from "react"
import { Drawer } from "@material-ui/core"
import SideBarItemList from "Components/UI/Molecules/SideBar"
import useStyle from "./styles"

const DrawerSideBar = (props) => {
  const classes = useStyle()
  return (
    <Drawer
      className={classes.drawer}
      variant={props.variant}
      open={props.open}
      onClose={props.onClose ? props.onClose : null}
      classes={{
        paper: classes.drawerPaper,
      }}
      anchor="left"
    >
      <SideBarItemList />
    </Drawer>
  )
}

export default DrawerSideBar
