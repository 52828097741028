import React, { useRef } from "react"

import DialogComponent from "Components/UI/Molecules/Modals/DialogComponent"
import FormPassword from "Components/UI/Atoms/NewPassword/Form"
import useCustomSnackbar from "Components/UI/Atoms/SnackBar"
import { interfaces } from "service/interfaces"

import CancelConfirmButtons from "Components/UI/Molecules/CancelConfirmButtons"

const ResetPassword = (props) => {
  const { open, handleClose, disabled, subtitle } = props

  const createDismissableSnackbar = useCustomSnackbar()
  const formSubmitRef = useRef(null)

  const handleSubmit = () => {
    formSubmitRef.current.click()
  }

  const onSubmit = async (values) => {
    await interfaces.insuranceInterface
      .resetPasswordHome(values)
      .then((res) => {
        createDismissableSnackbar(res.data, {
          variant: "success",
        })
        handleClose()
      })
  }

  return (
    <DialogComponent
      open={open}
      handleclose={handleClose}
      title="Cambio de contraseña"
      disableBackdropClick
      disableEscapeKeyDown
      children={
        <FormPassword
          subtitle={subtitle}
          ref={formSubmitRef}
          onSubmit={onSubmit}
        />
      }
      actions={
        <CancelConfirmButtons
          onClickSubmit={handleSubmit}
          onClickCancel={handleClose}
          disabledCancel={!disabled ? false : true}
        />
      }
    />
  )
}

export default ResetPassword
