import React, { useState } from "react"
import { IconButton, TextField } from "@material-ui/core"
import Grow from "@material-ui/core/Grow"
import ClearIcon from "@material-ui/icons/Clear"
import CheckIcon from "@material-ui/icons/Check"
import SearchIcon from "@material-ui/icons/Search"
import InputAdornment from "@material-ui/core/InputAdornment"

import useStyles from "./styles"

const SearchTable = (props) => {
  const classes = useStyles()
  const { confirmSearch, discardSearch, textPlaceholder } = props
  const [text, setText] = useState()

  return (
    <Grow appear in={true} timeout={300}>
      <div className={classes.firstDiv}>
        <TextField
          autoFocus
          className={classes.secondDiv}
          placeholder={textPlaceholder}
          onChange={(e) => setText(e.target.value.toUpperCase())}
          fullWidth
          onKeyPress={(event) => {
            if (event.key === "Enter") {
              confirmSearch(text)
            }
          }}
          value={text}
          InputProps={{
            classes: { input: classes.input },
            startAdornment: (
              <InputAdornment position="start" className={classes.colorWhite}>
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />
        <IconButton
          className={classes.colorWhite}
          onClick={() => {
            confirmSearch(text)
          }}
        >
          <CheckIcon />
        </IconButton>
        <IconButton
          className={classes.colorWhite}
          onClick={() => {
            discardSearch(text)
          }}
        >
          <ClearIcon />
        </IconButton>
      </div>
    </Grow>
  )
}

export default SearchTable
