import { makeStyles } from "@material-ui/core/styles"

export const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "transparent",
    color: "black",
  },
  title: {
    marginRight: "15px",
  },
  icon: {
    color: "white",
    backgroundColor: "#3E984C",
    "&:hover": {
      backgroundColor: "#2B6A35",
    },
  },
}))
