import React from "react"

import Modal from "@material-ui/core/Modal"
import Backdrop from "@material-ui/core/Backdrop"
import { useStyles } from "./styles"

const ModalSimple = (props) => {
  const classes = useStyles()

  return (
    <Modal
      innerRef={props.ref}
      disableEscapeKeyDown={props.disableEscapeKeyDown}
      className={classes.modal}
      open={props.open}
      onClose={props.onClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 400,
      }}
    >
      <div className={classes.paper}>{props.div}</div>
    </Modal>
  )
}

export default ModalSimple
