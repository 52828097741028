import React from "react"
import { Grid, TextField } from "@material-ui/core"

export default function InputsWithoutValidate(props) {
  const { spacing, fields, style } = props
  return (
    <div>
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        alignContent="center"
        spacing={spacing}
      >
        {fields.map((field, index) => {
          let { display = true } = field
          if (field.class === "textfield") {
            if (field.onChange) {
              return (
                <Grid
                  item
                  sm={field.sm}
                  key={index}
                  xs={field.xs}
                  md={field.md}
                >
                  <TextField
                    key={field.name}
                    disabled={field.disabled}
                    type={field.type}
                    label={field.label}
                    name={field.name}
                    onChange={field.onChange}
                    variant="outlined"
                    fullWidth
                    value={field.value}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    multiline={field.multiline}
                    maxRows={field.maxRows}
                    inputProps={field.inputProps}
                  />
                </Grid>
              )
            } else {
              return (
                <Grid
                  item
                  sm={field.sm}
                  key={index}
                  xs={field.xs}
                  md={field.md}
                >
                  <TextField
                    key={field.name}
                    disabled={field.disabled}
                    type={field.type}
                    fullWidth
                    label={field.label}
                    name={field.name}
                    variant="outlined"
                    value={field.value}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    InputProps={field.inputProps}
                    multiline={field.multiline}
                    maxRows={field.maxRows}
                  />
                </Grid>
              )
            }
          }
          if (field.class === "custom") {
            return (
              display && (
                <Grid
                  item
                  sm={field.sm}
                  xs={field.xs}
                  style={style}
                  md={field.md}
                >
                  {field.component}
                </Grid>
              )
            )
          }
          return ""
        })}
      </Grid>
    </div>
  )
}
