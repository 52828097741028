import { makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles((theme) => ({
  buttonLeftBack: {
    marginRight: "13px",
  },
  grow: {
    flexGrow: 1,
    background: "rgba(196, 196, 196, 0.25)",
  },
  appBar: {
    backgroundColor: "transparent",
    zIndex: "-1",
    [theme.breakpoints.up("sm")]: {
      width: `calc(100% - ${240}px)`,
      marginLeft: 240,
    },
    [theme.breakpoints.down("sm")]: {
      background:
        "linear-gradient(90deg, rgba(62,152,76,1) 35%, rgba(0,134,126,1) 100%)",
    },
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  },
  title: {
    display: "none",
    fontWeight: 600,
    marginRight: theme.spacing(1),
    [theme.breakpoints.up("sm")]: {
      display: "block",
    },
  },
  subTitle: {
    fontWeight: 500,
    fontSize: "15px",
    marginRight: theme.spacing(1),
    [theme.breakpoints.up("sm")]: {
      display: "inline",
    },
    [theme.breakpoints.down("sm")]: {
      color: "white",
    },
  },
  sectionDesktop: {
    display: "none",
    marginRight: "-13px",
    [theme.breakpoints.up("lg")]: {
      display: "flex",
    },
  },
  sectionMobile: {
    display: "flex",
    [theme.breakpoints.up("lg")]: {
      display: "none",
    },
    buttonIcon: {
      "&:hover": {
        backgroundColor: "transparent",
      },
    },
    iconButton: {
      width: "32px",
      height: "32px",
      padding: "none",
      marginRight: theme.spacing(2),
      "&:hover": {
        backgroundColor: "transparent",
      },
    },
    iconArrow: {
      width: "32px",
      height: "32px",
      marginLeft: "20px",
    },
  },
}))

export default useStyles
