import { makeStyles } from "@material-ui/core"

export const useStyles = makeStyles(() => ({
  allButtons: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    marginRight: ({ noMargin }) => (noMargin ? "" : "15px"),
    marginLeft: "15px",
    marginBottom: "10px",
  },
  buttons: {
    width: "100%",
    display: "flex",
    justifyContent: "flex-end",
  },
  textType: {
    textTransform: "initial",
  },
  cancel: {
    textTransform: "initial",
    marginRight: "8px",
  },
}))
