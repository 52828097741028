import React, { useState, useRef } from "react"

import Divider from "@material-ui/core/Divider"

import DialogComponent from "Components/UI/Molecules/Modals/DialogComponent"
import CustomButton from "Components/UI/Atoms/CustomButton"
import InputsWithoutValidate from "Components/UI/Molecules/InputsWithoutValidate"
import ModalSimple from "Components/UI/Molecules/Modals/Simple"
import useCustomSnackbar from "Components/UI/Atoms/SnackBar"
import { InputAdornment } from "@material-ui/core"
import { AttachMoney } from "@material-ui/icons"

import ReactImageGallery from "react-image-gallery"
import "react-image-gallery/styles/css/image-gallery.css"

import { interfaces } from "service/interfaces"
import { printPDF, titles, formatDate } from "Helpers/index"

const DialogInsurance = (props) => {
  const { open, onClose, info, state } = props
  const [openModal, setOpenModal] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const ref = useRef()
  const createDismissableSnackbar = useCustomSnackbar()

  const onSubmitPrint = async () => {
    setIsLoading(true)
    await interfaces.insuranceInterface
      .print(state.id)
      .then((res) => {
        printPDF(res).print()
        createDismissableSnackbar("Descarga exitosa!", {
          variant: "success",
        })
      })
      .catch()
      .finally()
    setIsLoading(false)
  }

  function handleOpenModal() {
    setOpenModal(true)
  }
  function handleCloseModal() {
    setOpenModal(false)
  }

  const objeto = [
    {
      class: "textfield",
      label: "Siniestro",
      name: "Siniestro",
      sm: 4,
      xs: 12,
      value: info.siniestro,
      disabled: true,
    },
    {
      class: "textfield",
      label: "Dominio",
      name: "dominio",
      sm: 4,
      xs: 12,
      value: info.dominio,
      disabled: true,
    },
    {
      label: "Tipo baja",
      name: "tipo_baja",
      sm: 4,
      xs: 12,
      class: "textfield",
      value: info.tipo,
      disabled: true,
    },
    {
      label: "Marca",
      name: "marca",
      sm: 4,
      xs: 12,
      class: "textfield",
      value: info.marca,
      disabled: true,
    },
    {
      label: "Modelo",
      name: "modelo",
      sm: 4,
      xs: 12,
      class: "textfield",
      value: info.modelo,
      disabled: true,
    },
    {
      label: "Año",
      name: "año",
      sm: 4,
      xs: 12,
      class: "textfield",
      type: "textfield",
      inputProps: { min: 0 },
      value: info.año,
      disabled: true,
    },
    {
      label: "Número de motor",
      name: "num_motor",
      sm: 6,
      xs: 12,
      class: "textfield",
      value: info.nro_motor,
      disabled: true,
    },
    {
      label: "Número de chasis",
      name: "num_chasis",
      sm: 6,
      xs: 12,
      class: "textfield",
      value: info.chasis_motor,
      disabled: true,
    },
    {
      class: "custom",
      sm: 12,
      xs: 12,
      component: <Divider />,
    },
    {
      class: "textfield",
      label: "Certificado baja",
      name: "certificado_baja",
      sm: 4,
      xs: 12,
      type: "textfield",
      value: info.certificado_baja,
      disabled: true,
    },
    {
      class: "textfield",
      label: "Nº formulario 04",
      name: "form_04",
      sm: 4,
      xs: 12,
      type: "textfield",
      value: info.form04,
      disabled: true,
    },
    {
      label: "Nº formulario 04-AM",
      name: "form_04AM",
      sm: 4,
      xs: 12,
      class: "textfield",
      type: "textfield",
      value: info.form04motor,
      disabled: true,
    },
    {
      class: "textfield",
      label: "Retiro asegurado",
      name: "retiro_asegurado",
      sm: 4,
      xs: 12,
      value: formatDate(info.retiro),
      disabled: true,
    },
    {
      class: "textfield",
      label: "Envío form. 04",
      name: "envio_form04",
      sm: 4,
      xs: 12,
      value: formatDate(info.envio),
      disabled: true,
    },
    {
      class: "textfield",
      label: "Siniestro en predio",
      name: "siniestro_predio",
      sm: 4,
      xs: 12,
      value: formatDate(info.ingreso),
      disabled: true,
    },
    {
      class: "textfield",
      label: "Asign. obleas",
      name: "asignacion_obleas",
      sm: 4,
      xs: 12,
      value: formatDate(info.obleas),
      disabled: true,
    },
    {
      class: "textfield",
      label: "Ingreso baja",
      name: "ingreso_baja",
      sm: 4,
      xs: 12,
      value: formatDate(info.baja),
      disabled: true,
    },
    {
      label: "Finaliz. DNRPA",
      name: "fin_dnrpa",
      sm: 4,
      xs: 12,
      class: "textfield",
      value: formatDate(info.finalizacion),
      disabled: true,
    },
    {
      class: "custom",
      sm: 12,
      xs: 12,
      component: <Divider />,
    },
    {
      type: "textfield",
      class: "textfield",
      label: "Cotización siniestro (IVA inc.)",
      name: "cotizacion_sinestro",
      sm: 4,
      xs: 12,
      value: info.cotizacion_siniestro,
      disabled: true,
      inputProps: {
        startAdornment: (
          <InputAdornment position="start">
            <AttachMoney />
          </InputAdornment>
        ),
      },
    },
    {
      type: "textfield",
      class: "textfield",
      label: "Cotización retiro (IVA inc.)",
      name: "cotizacion_retiro",
      sm: 4,
      xs: 12,
      value: info.cotizacion_retiro,
      disabled: true,
      inputProps: {
        startAdornment: (
          <InputAdornment position="start">
            <AttachMoney />
          </InputAdornment>
        ),
      },
    },
    {
      label: "Estado de pago",
      name: "estado",
      sm: 4,
      xs: 12,
      class: "textfield",
      value: info.estado,
      disabled: true,
    },
  ]

  return (
    <>
      <DialogComponent
        open={open}
        handleclose={onClose}
        handleclosedisabled={isLoading}
        disableBackdropClick={isLoading}
        disableEscapeKeyDown={isLoading}
        widthLarge={"900px"}
        title={
          <>
            <b> {info.dominio}</b> {info.marca} {info.modelo}
          </>
        }
        children={<InputsWithoutValidate spacing={2} fields={objeto} />}
        actions={
          <div style={{ paddingRight: "10px" }}>
            <CustomButton
              text="Fotografias"
              color="primary"
              style={{ margin: "5px" }}
              onClick={handleOpenModal}
              disabled={
                info.fotografias && info.fotografias.indexOf("") === -1
                  ? false
                  : true
              }
            />
            <CustomButton
              text="Imprimir ficha"
              onClick={onSubmitPrint}
              isLoading={isLoading}
              disabled={isLoading}
              color="primary"
              style={{ margin: "5px" }}
            />
          </div>
        }
      />
      <ModalSimple
        open={openModal}
        onClose={handleCloseModal}
        div={
          <ReactImageGallery
            ref={ref}
            items={titles(info.fotografias)}
            onClick={(e) => ref.current.fullScreen()}
            useBrowserFullscreen={false}
            showBullets={true}
            showPlayButton={false}
            lazyLoad={true}
            showThumbnails={false}
            onErrorImageURL={process.env.PUBLIC_URL + "/Images/unavailable.jpg"}
          />
        }
      />
    </>
  )
}

export default DialogInsurance
