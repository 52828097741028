import React from "react"

import { Grid, CssBaseline, Paper, Hidden } from "@material-ui/core"

import FormLogin from "Components/UI/Organisms/Login/"
import useStyles from "./styles"

const LoginTemplate = (props) => {
  const {
    text,
    login,
    validation,
    openNewPassword,
    setOpenNewPassword,
    openDialog,
    setOpenDialog,
    firstLabel,
    secondLabel,
    namefirstLabel,
    namesecondLabel,
    buttonLabel,
    showButton,
  } = props
  const classes = useStyles()

  return (
    <Grid container className={classes.root}>
      <CssBaseline />
      <Hidden smDown>
        <Grid item xs={false} sm={6} md={9} className={classes.image} />
      </Hidden>
      <Grid
        container
        item
        justifyContent="center"
        alignItems="center"
        xs={12}
        sm={12}
        md={3}
        component={Paper}
        elevation={6}
      >
        <FormLogin
          text={text}
          login={login}
          validation={validation}
          openNewPassword={openNewPassword}
          setOpenNewPassword={setOpenNewPassword}
          openDialog={openDialog}
          setOpenDialog={setOpenDialog}
          firstLabel={firstLabel}
          secondLabel={secondLabel}
          buttonLabel={buttonLabel}
          namefirstLabel={namefirstLabel}
          namesecondLabel={namesecondLabel}
          showButton={showButton}
        />
      </Grid>
    </Grid>
  )
}
export default LoginTemplate
