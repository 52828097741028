import React, { useState } from "react"

import DialogComponent from "Components/UI/Molecules/Modals/DialogComponent"
import useCustomSnackbar from "Components/UI/Atoms/SnackBar"
import { interfaces } from "service/interfaces"
import CancelConfirmButtons from "Components/UI/Molecules/CancelConfirmButtons"

const Export = (props) => {
  const { handleClose, open } = props
  const [isLoading, setIsLoading] = useState(false)
  const createDismissableSnackbar = useCustomSnackbar()

  const onSubmit = async () => {
    setIsLoading(true)
    await interfaces.insuranceInterface
      .exportData()
      .then((res) => {
        window.location.assign(res.data)
        createDismissableSnackbar("Descarga exitosa!", {
          variant: "success",
        })
        handleClose(true)
      })
      .catch()
      .finally()
    setIsLoading(false)
  }

  return (
    <DialogComponent
      tipo="center"
      open={open}
      handleclose={handleClose}
      handleclosedisabled={isLoading}
      disableBackdropClick={isLoading}
      disableEscapeKeyDown={isLoading}
      title="¿Desea exportar la tabla?"
      widthLarge="550px"
      actions={
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <CancelConfirmButtons
            submitText="Aceptar"
            onClickSubmit={onSubmit}
            onClickCancel={handleClose}
            isLoading={isLoading}
            disabledSubmit={isLoading}
            disabledCancel={isLoading}
          />
        </div>
      }
    />
  )
}

export default Export
