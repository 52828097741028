export const FORM_HOME = (values) => {
  return [
    {
      id: 1,
      class: "textfield",
      label: "Dominio",
      value: values.info.dominio,
      sm: 6,
      xs: 12,
      disabled: true,
    },
    {
      id: 2,
      class: "textfield",
      label: "Siniestro",
      value: values.info.nro_siniestro,
      sm: 6,
      xs: 12,
      disabled: true,
    },
    {
      id: 3,
      class: "textfield",
      label: "Compañia de seguros",
      value: values.info.cia_seguros,
      sm: 12,
      xs: 12,
      disabled: true,
    },
    {
      id: 4,
      class: "textfield",
      label: "Marca, modelo y año",
      value: `${values.info.marca} ${values.info.modelo}  ${values.info.año} `,
      sm: 12,
      xs: 12,
      disabled: true,
    },
    {
      id: 5,
      class: "textfield",
      label: "Modelo según título",
      value: values.info.modelo,
      sm: 12,
      xs: 12,
      disabled: true,
    },
    {
      id: 6,
      class: "textfield",
      label: "Número motor",
      value: values.info.motor_nro,
      sm: 12,
      xs: 12,
      disabled: true,
    },
    {
      id: 7,
      class: "textfield",
      label: "Número chasis",
      value: values.info.chasis_nro,
      sm: 12,
      xs: 12,
      disabled: true,
    },
  ]
}

export const FORM_RETIREMENT = (values) => {
  return [
    {
      id: 1,
      class: "textfield",
      label: "Fecha de retiro",
      value: values.retiro.fecha_retiro,
      sm: 6,
      xs: 12,
      disabled: true,
    },
    {
      id: 1,
      class: "textfield",
      label: "Chofer",
      value: values.retiro.chofer,
      sm: 6,
      xs: 12,
      disabled: true,
    },
    {
      id: 2,
      class: "textfield",
      label: "Teléfono",
      value: values.retiro.telefono,
      sm: 6,
      xs: 12,
      disabled: true,
    },
    {
      id: 3,
      class: "textfield",
      label: "Correo electrónico",
      value: values.retiro.correo,
      sm: 6,
      xs: 12,
      disabled: true,
    },
    {
      id: 4,
      class: "textfield",
      label: "Domicilio",
      value: values.retiro.domicilio,
      sm: 12,
      xs: 12,
      disabled: true,
    },
  ]
}
