import { makeStyles } from "@material-ui/core"

const useStyles = makeStyles(() => ({
  firstDiv: {
    display: "flex",
    marginTop: "40px",
    justifyContent: "flex-end",
  },
  button: {
    backgroundColor: "#3E984C",
    color: "#FFF",
    textTransform: "initial",
    "&:hover": {
      backgroundColor: "#019966",
    },
  },
}))

export default useStyles
