import React, { useState } from "react"

import LoginTemplate from "Components/UI/Templates/Login"

import FormDataContent from "Components/Hooks/ContextForm"
import { useHistory } from "react-router-dom"
import { interfaces } from "service/interfaces"
import { validationLogin } from "Components/UI/Validation"

const ContainerInsured = () => {
  const [openNewPassword, setOpenNewPassword] = useState(false)
  let history = useHistory()

  async function LoginInsurance(values, resetForm, setErrors, setFieldValue) {
    let user = values.user.toLowerCase()
    await interfaces.insuranceInterface
      .insuranceLogIn(user, values.password)
      .then((res) => {
        resetForm({})
        if (res.firstLogIn) {
          setOpenNewPassword(true)
        } else {
          setTimeout(() => {
            history.push("/aseguradoras")
          }, 1000)
        }
      })
      .catch((error) => {
        setErrors({
          user:
            error.response.data.data === "Usuario no encontrado"
              ? error.response.data.data
              : null,
          password:
            error.response.data.data === "Contraseña incorrecta"
              ? error.response.data.data
              : " ",
        })
        setFieldValue("password", "", null)
      })
  }

  return (
    <FormDataContent>
      <LoginTemplate
        text=" Inicio aseguradoras"
        login={LoginInsurance}
        validation={validationLogin}
        openNewPassword={openNewPassword}
        setOpenNewPassword={setOpenNewPassword}
        firstLabel="Usuario"
        secondLabel="Contraseña"
        namefirstLabel="user"
        namesecondLabel="password"
        buttonLabel="Iniciar Sesión"
        showButton={true}
      />
    </FormDataContent>
  )
}

export default ContainerInsured
