import React from "react"
import DialogTitle from "./DialogTitle"
import {
  DialogActions,
  DialogContent,
  Dialog,
  makeStyles,
} from "@material-ui/core"
import { slideStyles, centerStyles } from "./styles"

const DialogComponent = (props) => {
  const {
    open,
    handleclose,
    handleclosedisabled,
    title,
    actions,
    children,
    border = false,
    paddingReduce = false,
    transform = true,
    tipo,
    widthLarge,
    disableBackdropClick,
    disableEscapeKeyDown,
    subtitle,
  } = props

  const useStyles = makeStyles(tipo === "slide" ? slideStyles : centerStyles)
  const classes = useStyles({ border, paddingReduce, transform, widthLarge })

  return (
    <Dialog
      open={open}
      classes={{ paper: classes.paper }}
      onClose={handleclose}
      disableBackdropClick={disableBackdropClick}
      disableEscapeKeyDown={disableEscapeKeyDown}
    >
      <DialogTitle
        className={classes.title}
        handleClose={handleclose}
        subtitle={subtitle}
        handleclosedisabled={handleclosedisabled}
      >
        {title}
      </DialogTitle>
      <DialogContent classes={{ root: classes.dialogContent }}>
        {children}
      </DialogContent>
      {actions ? (
        <DialogActions classes={{ root: classes.dialogActions }}>
          {React.cloneElement(actions, { handleclose })}
        </DialogActions>
      ) : null}
    </Dialog>
  )
}

export default DialogComponent
